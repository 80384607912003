import React from "react";

const NotificationBell = ({ fill = "#001E62", ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 88 88"
    fill={fill}
    {...props}>
    <path
      d="M68.188,49.589l-1.831-2.436c-0.54-0.716-0.979-1.489-1.307-2.299c-1.074-2.651-1.598-5.508-1.557-8.49
	c0.027-1.917-0.005-3.679-0.031-4.127c-0.573-9.893-8.775-17.644-18.687-17.679v-0.003c-0.011,0-0.022,0.002-0.033,0.002
	s-0.022-0.002-0.033-0.002v0.003c-9.911,0.035-18.113,7.786-18.687,17.679c-0.026,0.448-0.058,2.21-0.031,4.126
	c0.041,2.982-0.482,5.838-1.557,8.49c-0.328,0.809-0.768,1.583-1.307,2.3l-1.831,2.434c-0.604,0.803-0.845,1.827-0.661,2.812
	c0.174,0.931,0.702,1.712,1.488,2.201c0.551,0.342,1.188,0.523,1.842,0.523h41.552c0.654,0,1.291-0.182,1.842-0.524
	c0.786-0.488,1.314-1.27,1.488-2.2C69.033,51.416,68.792,50.391,68.188,49.589z M44.776,51.556L44.776,51.556l-18.309,0.012
	c-0.903,0.001-1.418-1.031-0.875-1.753l0.388-0.516c0.725-0.964,1.318-2.008,1.762-3.104c1.254-3.095,1.866-6.419,1.817-9.879
	c-0.026-1.905,0.008-3.563,0.026-3.871c0.465-8.019,7.119-14.301,15.157-14.318c8.038,0.017,14.692,6.3,15.157,14.318
	c0.018,0.309,0.053,1.966,0.026,3.872c-0.048,3.461,0.563,6.785,1.817,9.878c0.444,1.097,1.037,2.142,1.763,3.105l0.572,0.764
	c0.46,0.615,0.021,1.493-0.747,1.493H44.776z"
    />
    <path
      d="M44.743,66.073c-4.264,0-7.732-2.796-7.732-6.232c0-0.957,0.775-1.732,1.732-1.732s1.732,0.775,1.732,1.732
	c0,1.5,1.955,2.768,4.268,2.768s4.268-1.268,4.268-2.768c0-0.957,0.775-1.732,1.732-1.732s1.732,0.775,1.732,1.732
	C52.475,63.277,49.006,66.073,44.743,66.073z"
    />
  </svg>
);
export default NotificationBell;
