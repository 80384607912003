import React from "react";

const Home = ({ stroke = "white", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 88 88"
    fill="none"
    stroke={stroke}
    strokeMiterlimit={10}
    strokeWidth={4}
    {...props}>
    <g>
      <g id="Layer_1">
        <g id="Home">
          <g>
            <path d="M70,34l-24.1-18.7c-.8-.6-1.9-.6-2.7,0l-25,18.8c-1,.8-1.6,1.9-1.6,3.2v32c0,2.2,1.8,4,4,4h47c2.2,0,4-1.8,4-4v-32c0-1.2-.6-2.4-1.5-3.2h0Z" />
            <path d="M35,72.2v-25c0-1.4,1.1-2.5,2.5-2.5h13c1.4,0,2.5,1.1,2.5,2.5v25" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Home;
