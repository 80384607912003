import React from "react";
import SelfcareRouter from "./routing";
import { SelfcareIntlProvider } from "./contexts/SelfcareIntlContext";
import { BrandingProvider } from "./contexts/BrandingContext";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ModalProvider } from "./components/modals";
import ChatWithUs from "./components/ChatWithUs";

function App() {
  return (
    <BrandingProvider>
      <SelfcareIntlProvider>
        <ModalProvider>
          <Provider store={store}>
            <SelfcareRouter />
            <ChatWithUs />
          </Provider>
        </ModalProvider>
      </SelfcareIntlProvider>
    </BrandingProvider>
  );
}

export default App;
