import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import Storage from "store2";
import { IntlProvider } from "react-intl";
import { BrandingContext } from "./BrandingContext";

const SelfcareIntlContext = React.createContext();

const formats = {
  // number: {
  //   USD: {
  //     style: "currency",
  //     currency: "USD",
  //   },
  // },
};

function SelfcareIntlProvider({ children }) {
  const { language } = useContext(BrandingContext);
  // Method to dynamically change the language
  const nextLanguage = locale => {
    let localeList = Object.keys(language.SUPPORTED_LOCALES);
    let idx = localeList.indexOf(locale);
    return localeList[(idx + 1) % localeList.length];
  };

  const switchLanguageTo = lang => {
    Storage.set("language", lang, true);
    setIntlParams({
      ...intlParams,
      locale: lang,
      messages: language.MESSAGES[lang],
    });
  };

  const storedLocale = Storage.get("language", language.INITIAL_LOCALE);

  const initLocale = Object.prototype.hasOwnProperty.call(language.SUPPORTED_LOCALES, storedLocale)
    ? storedLocale
    : language.INITIAL_LOCALE;

  if (initLocale !== storedLocale) {
    Storage.set("language", initLocale, true);
  }

  const [intlParams, setIntlParams] = useState({
    locale: initLocale,
    messages: language.MESSAGES[initLocale],
    nextLanguage: nextLanguage,
    switchLanguageTo: switchLanguageTo,
  });

  return (
    <SelfcareIntlContext.Provider value={intlParams}>
      <IntlProvider
        key={intlParams.locale}
        locale={intlParams.locale}
        messages={language.MESSAGES[intlParams.locale]}
        defaultLocale={language.INITIAL_LOCALE}
        formats={formats}>
        {children}
      </IntlProvider>
    </SelfcareIntlContext.Provider>
  );
}
SelfcareIntlProvider.propTypes = {
  children: PropTypes.node,
};
export { SelfcareIntlProvider, SelfcareIntlContext };
