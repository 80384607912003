import React, { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getNumericCodes } from "i18n-iso-countries";
import { Flex, Text } from "@theme-ui/components";
import _ from "lodash";

import ActiveTravelPlan from "./ActiveTravelPlan";
import EasyTravel from "./EasyTravel";
import PayPerUse from "./PayPerUse";
import TravelPacks from "./TravelPacks";
import { CRUISES_CODE, CANADA_ISO_CODE } from "../../common/Constants";
import Autocomplete from "../../components/Autocomplete/Autocomplete";
import GoBack from "../../components/GoBack";
import Spinner from "../../components/Spinner/Spinner";
import SubHeader from "../../components/SubHeader";
import Layout from "../../containers/Layout";

const TravelPlanPage = () => {
  const {
    update_account_product_status,
    travel_plan_status,
    non_shared_beneficiary_unit_status,
    cancel_SPC_status,
    additional_srv_status,
    travelPlan,
    travel_plan_config_status,
  } = useSelector(state => state.plan);
  const history = useHistory();

  const isLoading =
    travel_plan_status === "loading" ||
    update_account_product_status === "loading" ||
    additional_srv_status === "loading" ||
    cancel_SPC_status === "loading" ||
    non_shared_beneficiary_unit_status === "loading" ||
    travel_plan_config_status === "loading";

  const showEasyTravel =
    travelPlan && travelPlan.easyTravelCode !== "" && travelPlan.easyTravelServiceCode !== "";

  const intl = useIntl();
  const [selectedCountry, setSelectedCountry] = useState("");

  const countries = useMemo(() => {
    let countries = [];

    let regionNames = getNumericCodes();
    regionNames[CRUISES_CODE] = CRUISES_CODE;

    _.map(regionNames, isoCode => {
      if (isoCode == CRUISES_CODE) {
        countries.push({
          label: isoCode,
          value: intl.formatMessage({ id: "lbl.cruises" }),
        });
      } else if (isoCode !== CANADA_ISO_CODE) {
        countries.push({
          label: isoCode,
          value: intl.formatDisplayName(isoCode, { type: "region" }),
        });
      }
    });

    countries.sort(function (a, b) {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });

    return countries;
  }, [intl]);

  const onSelectCountry = country => {
    setSelectedCountry(country);
  };

  return (
    <Layout>
      <SubHeader />
      <Spinner isOpen={isLoading} />
      <ActiveTravelPlan />

      <Flex
        sx={{
          flexDirection: "column",
          mt: "2rem",
        }}>
        <Text color="textDark" sx={{ fontWeight: "semiBold" }}>
          <FormattedMessage id="lbl.where_travel" />
        </Text>
        <Flex
          sx={{
            width: "71%",
            gap: "4rem",
            alignItems: "center",
            mt: "2rem",
            ml: "3rem",
          }}>
          <Text color="textDark">
            <FormattedMessage id="lbl.select_country" />
          </Text>

          <Autocomplete
            placeHolder={intl.formatMessage({ id: "lbl.select_destination" })}
            items={countries}
            noItemFoundMessage={intl.formatMessage({ id: "lbl.no_country" })}
            onSelectItem={onSelectCountry}
          />
        </Flex>
      </Flex>

      {selectedCountry && showEasyTravel && <EasyTravel selectedCountry={selectedCountry} />}

      {selectedCountry && <TravelPacks selectedCountry={selectedCountry} />}

      {selectedCountry && <PayPerUse selectedCountry={selectedCountry} />}

      <GoBack mb="default" onBack={() => history.goBack()} />
    </Layout>
  );
};

export default TravelPlanPage;
