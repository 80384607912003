import React, { useState } from "react";
import PaymentDetailModel from "../PaymentDetailModel";
import { Box, Flex, Text } from "@theme-ui/components";
import { FormattedDate, FormattedMessage } from "react-intl";
import { SelfcareAmount } from "../base";

const HorizontalRule = () => {
  return <Box sx={{ width: "100%", height: "1px" }} bg="inactive"></Box>;
};

const PaymentRow = ({ payment, displaySeparator, gridColumns }) => {
  const [isOpen, setIsOpen] = useState(false);

  const getFormattedNumber = value => {
    let formattedValue;
    if (value == null) {
      formattedValue = <FormattedMessage id="lbl.not_available" />;
    } else {
      formattedValue = <SelfcareAmount amount={value} />;
    }

    return formattedValue;
  };

  return (
    <Flex sx={{ flexDirection: "column" }} bg="contentBg">
      <Box
        sx={{
          height: "2.5rem",
          alignItems: "center",
          textAlign: "left",
          display: "grid",
          gridGap: ["large", "large", "huge"],
          gridTemplateColumns: gridColumns,
        }}>
        <Text sx={{ color: "tableDate", fontFeatureSettings: "'tnum'" }}>
          <FormattedDate value={payment.transactionDate} dateStyle="medium" />
        </Text>

        <Text sx={{ color: "primary", textAlign: "left" }}>
          <FormattedMessage
            id="lbl.payment_tbl_with"
            values={{ paymentMethod: payment, paymentDescription: payment.description }}
          />
        </Text>

        <Text
          sx={{
            fontWeight: "semiBold",
            color: "primary",
            textAlign: "right",
            fontFeatureSettings: "'tnum'",
          }}>
          {getFormattedNumber(payment.value)}
        </Text>

        <Text
          variant="tablelink"
          onClick={() => setIsOpen(true)}
          sx={{ color: "primary", justifySelf: "flex-end", mr: "default" }}>
          <FormattedMessage id="lbl.view_details_arrow" />
        </Text>
      </Box>
      <PaymentDetailModel
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        payment={payment}
      />
      {displaySeparator && <HorizontalRule />}
    </Flex>
  );
};

export default PaymentRow;
