import React from "react";

const PaymentInformation = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 88 88"
    width="50"
    height="50"
    {...props}>
    <g>
      <g id="Paiement_x5F_information">
        <g>
          <path
            fill="#001e62"
            d="M68.5,19.5H19.5c-4.7,0-8.5,3.8-8.5,8.5v31.9c0,4.7,3.8,8.5,8.5,8.5h48.9c4.7,0,8.5-3.8,8.5-8.5v-31.9c0-4.7-3.8-8.5-8.5-8.5ZM15.3,28.1c0-2.3,1.9-4.3,4.3-4.3h48.9c2.3,0,4.3,1.9,4.3,4.3v2.1H15.3v-2.1ZM15.3,34.4h57.4v4.3H15.3v-4.3ZM72.7,59.9c0,2.3-1.9,4.3-4.3,4.3H19.5c-2.3,0-4.3-1.9-4.3-4.3v-17h57.4v17Z"
          />
          <path
            fill="#211f5e"
            d="M38.7,53.6h-14.9c-1.2,0-2.1,1-2.1,2.1s1,2.1,2.1,2.1h14.9c1.2,0,2.1-1,2.1-2.1s-1-2.1-2.1-2.1Z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default PaymentInformation;
