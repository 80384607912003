import React from "react";
import { useSelector } from "react-redux";

import { useThemeUI } from "@theme-ui/core";
import { Text, Flex } from "@theme-ui/components";

import { formatPhoneNumber } from "../../common/Utilities";
import BaseIcon from "../icons/base";

const PhoneDrop = ({ isMenuOpen }) => {
  const { theme } = useThemeUI();

  const { account, serviceAgreements } = useSelector(state => state.user);
  const enableDrop = serviceAgreements && serviceAgreements.length > 1;

  return (
    <Flex
      sx={{
        alignItems: "center",
        textAlign: "center",
        width: ["16rem", "16rem", "18rem"],
        height: "3.125rem",
        border: "solid 1px",
        borderColor: "border",
        borderRadius: "card",
        userSelect: "none",
        bg: "contentBg",
        px: ["small", "small", "default"],
      }}>
      <Flex
        bg={account && account?.accountStatus !== "ACTIVE" ? "disabled" : "primary"}
        sx={{
          borderRadius: "circle",
          width: "2rem",
          height: "2rem",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <BaseIcon name="phone" height="1.5rem" stroke={theme.colors.textLight} />
      </Flex>
      <Text
        mx="default"
        variant="cardtitle"
        sx={{
          fontWeight: "regular",
          fontFeatureSettings: "'tnum'",
          cursor: enableDrop ? "pointer" : "default",
        }}
        color={account?.accountStatus !== "ACTIVE" ? "disabled" : "primary"}>
        {account?.phoneNumber !== ""
          ? formatPhoneNumber(account?.phoneNumber)
          : account?.accountCode}
      </Text>

      {enableDrop && <BaseIcon name={isMenuOpen ? "upArrow" : "downArrow"} />}
    </Flex>
  );
};

export default PhoneDrop;
