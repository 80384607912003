import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Flex, Text, Progress, Box } from "@theme-ui/components";

import { getDaysUntil, isMoney } from "../../common/Utilities";
import { SelfcareIcon } from "../icons";
import { SelfcareAmount } from "../base";

const computeUnits = (
  baseUnitRate,
  unitDescription,
  totalQuantity,
  usedQuantity,
  usedByOwnerAccount,
  carriedOverQuantity
) => {
  let result = {
    total: null,
    used: null,
    unit: null,
    usedByOwner: null,
    carriedOverQuantity: null,
  };

  result.total = totalQuantity < 1 ? totalQuantity : totalQuantity / baseUnitRate;
  result.used = usedQuantity / baseUnitRate;
  result.usedByOwner = usedByOwnerAccount / baseUnitRate;
  result.carriedOverQuantity = carriedOverQuantity / baseUnitRate;

  result.unit = unitDescription;

  return result;
};

const formatPercentage = quantity => {
  return Number.parseInt(quantity.toFixed(1).replace(/[.,]0$/, ""));
};

const getFontSize = description => {
  const textLength = description.length;
  const maxLength = 34;
  let fontSize = 6;
  if (textLength >= maxLength) {
    fontSize = 4;
  }
  return [4, 4, fontSize];
};

const buildValidUntilDateDetails = (master, account, fub) => {
  const now = new Date().getTime();
  let billingPeriodToDate = master
    ? new Date(master.billingPeriod?.toDate)
    : account
    ? new Date(account.billingPeriod?.toDate)
    : new Date();
  let serviceExpirationDate = fub.serviceExpirationDate
    ? new Date(fub.serviceExpirationDate)
    : new Date("2050-12-31");
  if (serviceExpirationDate.getFullYear() === 2050) {
    return {
      expires: false,
      expired: false,
      serviceExpiration: false,
      validUntilDate: billingPeriodToDate,
      validUntilNoOfDays: getDaysUntil(billingPeriodToDate.getTime()) + 1,
    };
  } else {
    return {
      expires: true,
      expired: serviceExpirationDate.getTime() < now,
      serviceExpiration: true,
      validUntilDate: serviceExpirationDate,
      validUntilNoOfDays: Math.abs(getDaysUntil(serviceExpirationDate.getTime())),
    };
  }
};

const SAFUBCard = ({ fub, buildDescription, ...props }) => {
  const intl = useIntl();
  const { master, account } = useSelector(state => state.user);
  const { hiddenBdus, alwaysDisplayOverageBdus } = useSelector(state => state.plan);

  const fubUnits = computeUnits(
    fub.baseUnitRate,
    fub.unitDescription,
    fub.totalQuantity,
    fub.usedQuantity,
    fub.usedByOwnerAccount,
    fub.carriedOverQuantity
  );

  const shouldHideBeneficiaryUnit = fub => {
    return fub.bduType && hiddenBdus.includes(fub.bduType);
  };

  const shouldIncludeBeneficiaryUnit = fub => {
    return fub.bduType && alwaysDisplayOverageBdus.includes(fub.bduType);
  };

  // hide zero usage buckets if:
  // - overage and not part of always displayed list
  // - non-overage and part of hidden
  if (
    fubUnits.used === 0 &&
    ((fub.overage && !shouldIncludeBeneficiaryUnit(fub)) || shouldHideBeneficiaryUnit(fub))
  ) {
    return null;
  }

  const isShared =
    fub.shared ||
    (fub.participantsAccountCodes !== null && fub.participantsAccountCodes !== undefined) ||
    (fub.participants !== null && fub.participants !== undefined);

  const haveRollOver = fub.carriedOverQuantity > 0;

  const planTitle = buildDescription(fub);
  const planTitlefontSize = getFontSize(planTitle);

  const validUntilDateDetails = buildValidUntilDateDetails(master, account, fub);

  const searchMyConsumption = () => {
    const memberFound = fub.participants.find(sa => sa.accountCode === account.accountCode);
    const result = memberFound ? memberFound.usage / fub.baseUnitRate : 0;

    return result;
  };

  const isDataOrMoney = (consumedUnit = fub.unitDescription) => {
    const unitDescriptions = ["MB", "GB", "$"];
    return unitDescriptions.includes(consumedUnit);
  };

  // shows used units and the unit description
  const Consumed = ({ consumedAmount, consumedUnit = fub.unitDescription, ...props }) => {
    const unitDescription =
      !isDataOrMoney() && consumedAmount === 1
        ? consumedUnit.slice(0, consumedUnit.length - 1)
        : consumedUnit;
    if (isMoney(fub.unitDescription))
      return (
        <Text sx={{ fontWeight: "semiBold", ...props.sx }}>
          <SelfcareAmount amount={consumedAmount} />
        </Text>
      );
    return (
      <Text {...props} sx={{ fontWeight: "semiBold", ...props.sx }}>
        {/* format number */}
        <FormattedMessage
          id={isDataOrMoney() ? "lbl.consumedDecimal" : "lbl.consumed"}
          values={{
            amount: consumedAmount,
            unit: <FormattedMessage id={`lbl.${unitDescription}`} />,
          }}
        />
      </Text>
    );
  };

  // shows the description of the plan
  const PlanDescription = () => (
    <Flex
      my="small"
      sx={{
        height: "3.75rem",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
        flexDirection: "column",
      }}>
      <Text variant="headline" sx={{ lineHeight: 1, fontSize: planTitlefontSize, my: "auto" }}>
        {planTitle}
      </Text>
    </Flex>
  );

  // shows the prograss bar with the infinity icon or percentage (based on total quantity)
  const ProgressBar = () => {
    if (fub.totalQuantity > 0) {
      return (
        <Flex mb="tiny" sx={{ alignItems: "center", justifyContent: "center" }}>
          <Flex
            sx={{
              backgroundColor: "progressBg",
              height: "1.375rem",
              width: "80%",
              borderRadius: 10,
              alignItems: "center",
            }}>
            <Progress max={1} value={fubUnits.used / fubUnits.total} />
          </Flex>

          <Text ml="tiny" sx={{ width: "2.9rem", textAlign: "right" }}>
            {formatPercentage((fubUnits.used / fubUnits.total) * 100)}%
          </Text>
        </Flex>
      );
    }
    return (
      <Flex
        data-testid="infinity"
        mb="tiny"
        p="tiny"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "progressBg",
          width: "100%",
          borderRadius: 10,
          height: "1.375rem",
        }}>
        <SelfcareIcon name="infinity" />
      </Flex>
    );
  };

  // shows "used" or "out of" message (based on total quantity)
  const TotalUsed = () => {
    if (fub.totalQuantity > 0) {
      return (
        <Text mb="tiny" sx={{ textAlign: "center" }}>
          <Consumed consumedAmount={fubUnits.used} />
          <Text mx="tiny">
            <FormattedMessage id="lbl.out_of" />
          </Text>
          <Consumed consumedAmount={fubUnits.total} />
        </Text>
      );
    }
    return (
      <Text mb="tiny" sx={{ textAlign: "center" }}>
        <Consumed consumedAmount={fubUnits.used} />
        <Text ml="tiny">{intl.formatMessage({ id: "lbl.used" }).toLocaleLowerCase()}</Text>
      </Text>
    );
  };

  // shows "my usage" message (only for shared plan)
  const MyConsumption = () => {
    if (isShared) {
      const consumed = searchMyConsumption();

      return (
        <Flex mb="tiny" sx={{ justifyContent: "center", alignItems: "center", height: "1.5rem" }}>
          <Text sx={{ textAlign: "center", fontSize: [1, 1, 2, 2] }}>
            <Text color="primary" sx={{ fontSize: [1, 1, 2, 2] }}>
              <FormattedMessage id="lbl.my_data_consump" />
            </Text>
            <Consumed
              color="primary"
              ml="tiny"
              consumedAmount={consumed}
              sx={{ fontSize: [1, 1, 2, 2] }}
            />
            {fubUnits.total > 0 && (
              <Text color="primary" ml="tiny" sx={{ fontSize: [1, 1, 2, 2] }}>
                ({formatPercentage((consumed / fubUnits.total) * 100) + "%"})
              </Text>
            )}
          </Text>
        </Flex>
      );
    }
    return <Box sx={{ mb: "tiny", height: "1.5rem" }} />;
  };

  // shows "current cycle date — days" message
  const ValidUntil = () => {
    return (
      <Flex sx={{ flexDirection: "column", alignItems: "center", height: "3rem" }}>
        <Text sx={{ fontWeight: "medium" }}>
          {validUntilDateDetails.serviceExpiration && (
            <FormattedMessage id="lbl.cc.expiration_date" />
          )}
          {!validUntilDateDetails.serviceExpiration && <FormattedMessage id="lbl.cycle_ends" />}
        </Text>

        <Text>
          {intl.formatDate(validUntilDateDetails.validUntilDate, {
            year: "numeric",
            month: "short",
            day: "2-digit",
          })}
        </Text>
      </Flex>
    );
  };

  // shows "rolled over this cycle" message
  const RollOver = () => (
    <Text variant="" sx={{ textAlign: "center", fontSize: 1 }}>
      <FormattedMessage
        id="lbl.rolledOver"
        values={{
          qty: (fub.carriedOverQuantity / fub.baseUnitRate).toFixed(2),
          unit: <FormattedMessage id={`lbl.${fub.unitDescription}`} />,
        }}
      />
    </Text>
  );

  return (
    <Flex variant="layout.card" sx={{ padding: "0.5rem !important", ...props.sx }}>
      <Flex sx={{ flexDirection: "column", px: ["small", "default"] }}>
        <PlanDescription />
        <ProgressBar />
      </Flex>
      <Flex sx={{ flexDirection: "column", flexGrow: 1 }}>
        <TotalUsed />
        <MyConsumption />
        <ValidUntil />
        {haveRollOver && <RollOver />}
      </Flex>
    </Flex>
  );
};

SAFUBCard.displayName = "SAFUBCard";
export default SAFUBCard;
