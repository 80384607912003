import React, { useContext } from "react";

import { BrandingContext } from "../../contexts/BrandingContext";

import { IconButton } from "@theme-ui/components";
import CogecoIcon from "./cogeco";
import BaseIcon from "./base";

const SelfcareIcon = ({ name, onClick, ...props }) => {
  const { provider } = useContext(BrandingContext);
  let IconComponent;
  switch (provider) {
    case "base":
    case "el":
      IconComponent = BaseIcon;
      break;
    case "cogeco":
      IconComponent = CogecoIcon;
      break;
    default:
      IconComponent = BaseIcon;
      break;
  }
  return (
    <IconButton type="button" onClick={onClick} sx={{ ...props.sx }}>
      <IconComponent name={name} {...props} />
    </IconButton>
  );
};

export { SelfcareIcon };
