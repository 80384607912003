import React from "react";

const MyServices = ({ stroke = "#FFF", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 88 88" fill="none" {...props}>
    <g>
      <g id="Lines">
        <g>
          <rect
            fill="none"
            stroke={stroke}
            strokeWidth="0.25rem"
            strokeMiterlimit={10}
            x="29"
            y="15.5"
            width="30"
            height="57"
            rx="1.3"
            ry="1.3"
          />
          <g>
            <path
              strokeWidth="0.206rem"
              d="M25.9,67.2h-11.8c-.6,0-1.1-.5-1.1-1.1V21.6c0-.6.5-1.1,1.1-1.1h11.8"
            />
            <line strokeWidth="0.094rem" x1="14" y1="57.5" x2="26" y2="57.5" />
          </g>
          <g>
            <path
              strokeWidth="0.206rem"
              d="M62.1,67.2h11.8c.6,0,1.1-.5,1.1-1.1V21.6c0-.6-.5-1.1-1.1-1.1h-11.8"
            />
            <line strokeWidth="0.094rem" x1="74" y1="57.5" x2="62" y2="57.5" />
          </g>
          <line strokeWidth="0.094rem" x1="30" y1="60.5" x2="57" y2="60.5" />
          <circle
            stroke={stroke}
            strokeMiterlimit={10}
            strokeWidth="0.25rem"
            cx="44.5"
            cy="66"
            r="2.5"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default MyServices;
