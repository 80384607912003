import React from "react";
const ViewUsageDetails = ({ fill = "none", stroke = "#fff", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 30 30" {...props}>
    {/* <defs>
        <style>
            .cls-1,.cls-2{fill:none;stroke:#fff;strokeLinecap:round;strokeLinejoin:round;strokeWidth:2px}.cls-1{fillRule:evenodd}
        </style>
    </defs> */}
    <g
      id="Free-Pack_gauge-dashboard-1"
      data-name="Free-Pack / gauge-dashboard-1"
      transform="translate(1 1)">
      <g id="Group_32">
        <g id="Light_32">
          <path
            id="Oval_25"
            className="cls-1"
            d="M62.5 242.5a14 14 0 1 0-14-14 14 14 0 0 0 14 14z"
            transform="translate(-48.5 -214.5)"
            fill={fill}
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            fillRule="evenodd"
          />
          <path
            id="Shape_209"
            className="cls-1"
            d="M61.9 228.577a2.286 2.286 0 0 1-3.233-3.233c.893-.893 9.3-6.059 9.3-6.059s-5.167 8.4-6.067 9.292z"
            transform="translate(-46 -213.244)"
            fill={fill}
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Shape_210"
            className="cls-2"
            d="M51.5 226h1.715"
            transform="translate(-48.071 -212)"
            fill={fill}
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            fillRule="evenodd"
          />
          <path
            id="Shape_211"
            className="cls-2"
            d="m53.99 219.99 1.21 1.21"
            transform="translate(-47.287 -213.287)"
            fill={fill}
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Shape_212"
            className="cls-2"
            d="M60 217.5v1.715"
            transform="translate(-46 -214.071)"
            fill={fill}
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Shape_213"
            className="cls-2"
            d="M68.715 226H67"
            transform="translate(-44.144 -212)"
            fill={fill}
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Shape_214"
            className="cls-2"
            d="M69.632 233.545A16.469 16.469 0 0 0 60.5 231a16.469 16.469 0 0 0-9.132 2.545"
            transform="translate(-46.499 -210.332)"
            fill={fill}
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default ViewUsageDetails;
