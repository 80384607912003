import React from "react";

const AccountDetails = ({ ...props }) => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M22.7088 20.3601C23.7804 19.5774 24.577 18.4759 24.9846 17.2131C25.3923 15.9503 25.3901 14.591 24.9785 13.3295C24.5669 12.068 23.7668 10.969 22.6928 10.1897C21.6187 9.41038 20.3258 8.99072 18.9988 8.99072C17.6718 8.99072 16.3789 9.41038 15.3049 10.1897C14.2308 10.969 13.4308 12.068 13.0191 13.3295C12.6075 14.591 12.6053 15.9503 13.013 17.2131C13.4207 18.4759 14.2172 19.5774 15.2888 20.3601C13.7798 21.0111 12.4698 22.0497 11.4916 23.3703C10.5133 24.691 9.90176 26.2468 9.71881 27.8801C9.70212 28.0123 9.71203 28.1466 9.74796 28.2749C9.78389 28.4032 9.84511 28.5231 9.92803 28.6275C10.0109 28.7318 10.1139 28.8185 10.2308 28.8825C10.3477 28.9465 10.4762 28.9865 10.6088 29.0001C10.7395 29.014 10.8716 29.0019 10.9976 28.9647C11.1237 28.9274 11.2411 28.8657 11.3433 28.7831C11.4454 28.7004 11.5303 28.5984 11.593 28.483C11.6557 28.3675 11.6951 28.2408 11.7088 28.1101C11.9184 26.3238 12.7765 24.6766 14.1204 23.4812C15.4642 22.2857 17.2002 21.6254 18.9988 21.6254C20.7974 21.6254 22.5334 22.2857 23.8772 23.4812C25.2211 24.6766 26.0792 26.3238 26.2888 28.1101C26.316 28.3558 26.4332 28.5828 26.6179 28.7471C26.8026 28.9115 27.0416 29.0016 27.2888 29.0001H27.3988C27.5295 28.9864 27.6562 28.947 27.7717 28.8843C27.8872 28.8216 27.9891 28.7367 28.0718 28.6346C28.1545 28.5324 28.2162 28.4149 28.2534 28.2889C28.2906 28.1629 28.3027 28.0308 28.2888 27.9001C28.1073 26.2625 27.4954 24.7022 26.5153 23.3779C25.5352 22.0535 24.2218 21.0123 22.7088 20.3601ZM14.7088 15.2901C14.7108 14.442 14.9641 13.6136 15.4367 12.9094C15.9093 12.2053 16.5799 11.657 17.364 11.3338C18.1481 11.0106 19.0103 10.9271 19.8419 11.0937C20.6734 11.2604 21.4368 11.6698 22.0358 12.2701C22.6348 12.8705 23.0424 13.6349 23.2071 14.4668C23.3718 15.2987 23.2863 16.1608 22.9613 16.9441C22.6363 17.7274 22.0864 18.3968 21.3812 18.8678C20.6759 19.3387 19.8469 19.5901 18.9988 19.5901C17.8601 19.5874 16.769 19.1332 15.9647 18.3271C15.1605 17.521 14.7088 16.4288 14.7088 15.2901Z"
      fill="#001E62"
    />
    <path
      d="M25.9108 5.7501C25.7479 5.74767 25.588 5.70545 25.4451 5.62713C25.3022 5.54881 25.1807 5.43676 25.0909 5.30072C25.0012 5.16468 24.9461 5.00878 24.9304 4.84659C24.9147 4.6844 24.9389 4.52082 25.0008 4.3701C25.0442 4.23552 25.112 4.11009 25.2008 4.0001C25.298 3.91177 25.4097 3.84074 25.5308 3.7901C25.7743 3.69008 26.0474 3.69008 26.2908 3.7901C26.4136 3.8377 26.5257 3.90906 26.6208 4.0001C26.7092 4.0973 26.7802 4.20892 26.8308 4.3301C26.8931 4.4816 26.9172 4.64607 26.901 4.80905C26.8847 4.97203 26.8288 5.12854 26.7379 5.26481C26.6471 5.40109 26.5241 5.51297 26.3799 5.59061C26.2357 5.66825 26.0746 5.70929 25.9108 5.7101V5.7501Z"
      fill="#FF6D70"
    />
    <path
      d="M29.9099 15.7501C29.7783 15.7508 29.6479 15.7256 29.526 15.6758C29.4042 15.6261 29.2934 15.5528 29.1999 15.4601C29.1073 15.3666 29.0339 15.2558 28.9842 15.134C28.9344 15.0121 28.9092 14.8817 28.9099 14.7501C28.905 14.6835 28.905 14.6167 28.9099 14.5501C28.9209 14.4874 28.9411 14.4268 28.9699 14.3701C28.9918 14.3064 29.0221 14.2458 29.0599 14.1901L29.1999 14.0001L29.3499 13.8801C29.4057 13.8422 29.4662 13.812 29.5299 13.7901C29.5895 13.7596 29.6537 13.7393 29.7199 13.7301C29.9115 13.6896 30.111 13.7106 30.2899 13.7901C30.4127 13.8377 30.5248 13.909 30.6199 14.0001C30.6629 14.0476 30.703 14.0977 30.7399 14.1501C30.7778 14.2058 30.808 14.2664 30.8299 14.3301C30.8588 14.3868 30.879 14.4474 30.8899 14.5101C30.8948 14.5767 30.8948 14.6435 30.8899 14.7101C30.8899 14.9753 30.7846 15.2296 30.597 15.4172C30.4095 15.6047 30.1551 15.7101 29.8899 15.7101L29.9099 15.7501Z"
      fill="#FF6D70"
    />
    <path
      d="M19.0011 35.9999C14.4924 36.0609 10.1442 34.3283 6.91291 31.1833C3.68166 28.0384 1.83209 23.7386 1.77109 19.2299C1.71009 14.7212 3.44265 10.373 6.58764 7.14171C9.73262 3.91046 14.0324 2.06089 18.5411 1.99989C19.6828 1.96956 20.8248 2.04993 21.9511 2.23989C22.0814 2.26166 22.206 2.30901 22.3179 2.37923C22.4298 2.44945 22.5266 2.54113 22.6029 2.64898C22.6791 2.75683 22.7332 2.87872 22.7621 3.0076C22.791 3.13649 22.794 3.26982 22.7711 3.39989C22.7482 3.52927 22.7 3.65286 22.6292 3.7636C22.5585 3.87433 22.4667 3.97005 22.3589 4.04528C22.2512 4.1205 22.1297 4.17376 22.0014 4.202C21.8731 4.23025 21.7404 4.23293 21.6111 4.20989C20.6205 4.04083 19.6156 3.97048 18.6111 3.99989C15.6446 4.07818 12.768 5.03462 10.3452 6.7482C7.9224 8.46178 6.06233 10.8555 5.00031 13.6265C3.93829 16.3975 3.72205 19.4212 4.37895 22.3151C5.03585 25.209 6.53637 27.8431 8.69065 29.884C10.8449 31.9249 13.5562 33.2809 16.4814 33.7805C19.4065 34.2802 22.4142 33.9009 25.1237 32.6908C27.8333 31.4806 30.1231 29.494 31.7033 26.9822C33.2835 24.4704 34.0831 21.5463 34.0011 18.5799C33.9859 17.9095 33.9224 17.2411 33.8111 16.5799C33.7677 16.3187 33.8296 16.0509 33.9833 15.8354C34.1371 15.6198 34.37 15.474 34.6311 15.4299C34.7611 15.4085 34.8941 15.413 35.0224 15.4432C35.1507 15.4734 35.2718 15.5286 35.3787 15.6058C35.4856 15.6829 35.5761 15.7804 35.6452 15.8927C35.7142 16.0049 35.7604 16.1297 35.7811 16.2599C35.9079 17.0104 35.9814 17.769 36.0011 18.5299C36.0637 20.7626 35.6857 22.9857 34.8887 25.0722C34.0917 27.1588 32.8913 29.0678 31.3562 30.6902C29.8211 32.3126 27.9813 33.6166 25.942 34.5277C23.9027 35.4388 21.7038 35.939 19.4711 35.9999H19.0011Z"
      fill="#001E62"
    />
    <path
      d="M31.2109 4L29.8809 6.67L27.2109 8L29.8809 9.33L31.2109 12L32.5509 9.33L35.2109 8L32.5509 6.67L31.2109 4Z"
      fill="#FF6D70"
    />
  </svg>
);
export default AccountDetails;
