import React from "react";

const Edit = ({ stroke = "#001E62", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 88 88"
    fill="none"
    stroke={stroke}
    strokeLinecap="round"
    strokeMiterlimit={10}
    strokeWidth="0.25rem"
    width="30"
    height="30"
    {...props}>
    <g>
      <g id="Edit">
        <path d="M65.7,31.1l-32.3,32.3c-.6.6-1.4,1.1-2.3,1.3l-10.2,2.6c-.2,0-.4-.1-.3-.3l3-9.8c.2-.8.7-1.5,1.2-2.1L57.2,22.6c2.3-2.3,6.1-2.3,8.5,0h0c2.3,2.3,2.3,6.1,0,8.5Z" />
      </g>
    </g>
  </svg>
);
export default Edit;
