import React from "react";

const Language = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 88 88" {...props}>
    <g>
      <g id="Layer_1">
        <g>
          <path
            fill="#FFF"
            d="M43.4,11.8c-16.9,0-30.6,13.8-30.6,30.6s13.8,30.6,30.6,30.6,1.9-.9,1.9-1.9-.9-1.9-1.9-1.9c-4.8,0-10.2-10.1-10.6-24.9h39.4c1.1,0,1.9-.9,1.9-1.9,0-16.9-13.8-30.6-30.6-30.6ZM35.3,68c-10.3-3.3-17.9-12.5-18.7-23.7h12.3c.3,10,2.7,18.5,6.4,23.7ZM28.9,40.5h-12.3c.8-11.1,8.4-20.4,18.7-23.7-3.6,5.1-6.1,13.7-6.4,23.7ZM43.4,15.6c4.8,0,10.2,10.1,10.6,24.9h-21.2c.4-14.8,5.8-24.9,10.6-24.9ZM57.8,40.5c-.3-10-2.7-18.5-6.3-23.6,10.2,3.3,17.9,12.5,18.7,23.6h-12.3Z"
          />
          <path
            fill="#FF6D72"
            d="M74.1,59.2l-24.2-10.8c-.7-.3-1.6-.2-2.1.4s-.7,1.4-.4,2.1l10.8,24.2c.3.7,1,1.1,1.7,1.1h.1c.8,0,1.5-.6,1.7-1.4l2.8-9.3,9.3-2.8c.8-.2,1.3-.9,1.4-1.7,0-.8-.4-1.6-1.1-1.9Z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default Language;
