import React from "react";

const Search = ({ fill = "#001E62", ...props }) => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    {...props}>
    <path
      d="M4 16.5C4 9.59644 9.59644 4 16.5 4C23.4036 4 29 9.59644 29 16.5C29 23.4036 23.4036 29 16.5 29C9.59644 29 4 23.4036 4 16.5ZM16.5 2C8.49187 2 2 8.49187 2 16.5C2 24.5081 8.49187 31 16.5 31C20.014 31 23.236 29.75 25.7457 27.6704L33.5325 35.4666C33.9228 35.8574 34.556 35.8577 34.9467 35.4674C35.3375 35.0771 35.3378 34.444 34.9476 34.0532L27.195 26.2913C29.558 23.7116 31 20.2742 31 16.5C31 8.49187 24.5081 2 16.5 2Z"
      fill={fill}
    />
    <path
      d="M23.3574 17.6661C23.8731 17.8636 24.131 18.4419 23.9335 18.9576C23.3478 20.4864 22.3126 21.8018 20.9644 22.7305C19.6162 23.6592 18.0182 24.1575 16.3811 24.1599L16.3791 24.1599C14.7903 24.159 13.2371 23.6897 11.9137 22.8108C10.5902 21.9319 9.55524 20.6823 8.93815 19.2183C8.72363 18.7094 8.9623 18.1229 9.47122 17.9084C9.98014 17.6939 10.5666 17.9325 10.7811 18.4415C11.2455 19.5431 12.0243 20.4833 13.0201 21.1447C14.0157 21.8059 15.184 22.159 16.3792 22.1599C17.6117 22.1579 18.8148 21.7826 19.8299 21.0834C20.8452 20.3841 21.6248 19.3934 22.0658 18.2421C22.2634 17.7264 22.8416 17.4685 23.3574 17.6661Z"
      fill={fill}
    />
  </svg>
);
export default Search;
