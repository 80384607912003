import React from "react";
import { useSelector } from "react-redux";

import { Flex, Text } from "@theme-ui/components";

import { FormattedMessage } from "react-intl";
import BaseIcon from "../icons/base";

const SharedPlanDrop = ({ isMenuOpen }) => {
  const { selectedSharedPlan } = useSelector(state => state.plan);

  return (
    <Flex
      sx={{
        border: "solid 1px",
        borderColor: "border",
        borderRadius: "card",
        width: ["100%", "30rem", "21.6875rem"],
        height: "3.125rem",
        alignItems: "center",
        cursor: "pointer",
        userSelect: "none",
        justifyContent: "space-between",
      }}>
      <Flex ml="default">
        <Text
          variant="cardtitle"
          sx={{
            width: "100%",
            overflow: "hidden",
            display: "inline-block",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontWeight: "regular",
          }}>
          <FormattedMessage
            id="lbl.bdu_type_description"
            values={{
              isOverage: selectedSharedPlan.overage ? "yes" : "no",
              bduDescription: selectedSharedPlan.bduTypeDescription,
            }}
          />
        </Text>
      </Flex>
      <Flex mr="default">
        <BaseIcon sx={{ cursor: "pointer" }} name={isMenuOpen ? "upArrow" : "downArrow"} />
      </Flex>
    </Flex>
  );
};

export default SharedPlanDrop;
