import React from "react";

const ServiceFeatures = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88" {...props}>
    <g>
      <g>
        <path
          fill="#001E62"
          d="M62.529,75.5H25.471c-4.088,0-7.412-3.324-7.412-7.412V29.176c0-4.088,3.324-7.412,7.412-7.412
        c1.024,0,1.853,0.829,1.853,1.853c0,1.024-0.829,1.853-1.853,1.853c-2.043,0-3.706,1.663-3.706,3.706v38.912
        c0,2.043,1.663,3.706,3.706,3.706h37.059c2.043,0,3.706-1.663,3.706-3.706V29.176c0-2.043-1.663-3.706-3.706-3.706
        c-1.024,0-1.853-0.829-1.853-1.853c0-1.024,0.829-1.853,1.853-1.853c4.088,0,7.412,3.324,7.412,7.412v38.912
        C69.941,72.176,66.617,75.5,62.529,75.5z"
        />
      </g>
      <g>
        <path
          fill="#001E62"
          d="M51.412,27.324H36.588c-3.065,0-5.559-2.494-5.559-5.559s2.494-5.559,5.559-5.559h2.17
        C39.523,14.049,41.584,12.5,44,12.5s4.477,1.549,5.242,3.706h2.17c3.065,0,5.559,2.494,5.559,5.559S54.477,27.324,51.412,27.324z
         M36.588,19.912c-1.022,0-1.853,0.831-1.853,1.853s0.831,1.853,1.853,1.853h14.824c1.022,0,1.853-0.831,1.853-1.853
        s-0.831-1.853-1.853-1.853h-3.706c-1.024,0-1.853-0.829-1.853-1.853c0-1.022-0.831-1.853-1.853-1.853s-1.853,0.831-1.853,1.853
        c0,1.024-0.829,1.853-1.853,1.853H36.588z M49.559,18.059h0.018H49.559z"
        />
      </g>
      <g>
        <path
          fill="#FF6D72"
          d="M55.118,40.294H32.882c-1.024,0-1.853-0.829-1.853-1.853s0.829-1.853,1.853-1.853h22.235
        c1.024,0,1.853,0.829,1.853,1.853S56.142,40.294,55.118,40.294z"
        />
      </g>
      <g>
        <path
          fill="#FF6D72"
          d="M55.118,51.412H32.882c-1.024,0-1.853-0.829-1.853-1.853s0.829-1.853,1.853-1.853h22.235
        c1.024,0,1.853,0.829,1.853,1.853S56.142,51.412,55.118,51.412z"
        />
      </g>
      <g>
        <path
          fill="#FF6D72"
          d="M55.118,62.529H32.882c-1.024,0-1.853-0.829-1.853-1.853c0-1.024,0.829-1.853,1.853-1.853h22.235
        c1.024,0,1.853,0.829,1.853,1.853C56.971,61.701,56.142,62.529,55.118,62.529z"
        />
      </g>
    </g>
  </svg>
);
export default ServiceFeatures;
