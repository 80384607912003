import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Flex, Text, Heading, Button, Spinner } from "@theme-ui/components";

import { PAYMENT_METHOD } from "../../common/Constants";
import Layout from "../../containers/Layout";
import { useSelector, useDispatch } from "react-redux";

import { HostedCcOperationRequest } from "..";

import PaymentResponseModal from "../../pages/Home/PaymentResponseModal";
import {
  getMaxAllowedAmount,
  getPaymentDetails,
  setPaymentError,
  setPaymentResult,
  setPaymentResultStatus,
} from "../../redux/slices/PaymentSlice";
import { FormInput, SelfcareAmount } from "../../components/base";
import { useForm } from "react-hook-form";
import { SelfcareIntlContext } from "../../contexts/SelfcareIntlContext";
import { isEmpty } from "../../common/Utilities";
import { getBillingSummary, getInvoices, getPayments } from "../../redux/slices/BillingSlice";
import GoBack from "../../components/GoBack";
import { useHistory } from "react-router-dom";
import { StyledModalMessage } from "../../components/modals";
import MakePaymentSkeleton from "./MakePaymentSkeleton";
import { debounce } from "lodash";
import { BrandingContext } from "../../contexts/BrandingContext";
import BaseIcon from "../../components/icons/base";

const MakePayment = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const { locale } = useContext(SelfcareIntlContext);
  const { language } = useContext(BrandingContext);
  const { master, defaultNavigationPath } = useSelector(state => state.user);
  const { billingSummary } = useSelector(state => state.billing);
  const {
    paymentResult,
    payment_result_status,
    maxAmount,
    paymentDetails,
    payment_details_status,
  } = useSelector(state => state.payment);

  const [hasCreditCardDetails, setHasCreditCardDetails] = useState(false);
  const [displayconfirmation, setDisplayConfirmation] = useState(false);
  const [payment, setPayment] = useState({ amount: 0, card: 0 });
  const [payNowClicked, setPayNowClicked] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState({});
  const [isCompleted, setIsCompleted] = useState(false);
  const [isError, setIsError] = useState(false);
  const accountCode = master?.accountCode;

  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const reactHookFormHandle = { register, errors };

  function payNowClick(value) {
    // for the French language, commas are used as decimal separators and dots are used as thousands separators
    const thousandsSeparator = locale == language.LANGUAGE_ISOCODE.French ? "." : ",";
    // remove the thousands separator from the input amount
    const removeThousandsSeparator = value.amount.replaceAll(thousandsSeparator, "");
    // replace the french decimal separator with the english decimal separator
    const validAmount = removeThousandsSeparator.replace(",", ".");
    // set the valid amount
    setPayment({ amount: validAmount, card: paymentMethod });
    paymentMethod === PAYMENT_METHOD.SAVED ? setDisplayConfirmation(true) : setPayNowClicked(true);
  }

  useEffect(() => {
    if (billingSummary?.balance < 0) {
      const formattedNumber = intl.formatNumber(Math.abs(billingSummary.balance), {
        minimumFractionDigits: 2,
      });
      const amount = formattedNumber.replaceAll(/\s/g, ".");
      setValue("amount", amount);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingSummary]);

  const isCreditCardValid = ccDetails => {
    const expYear = ccDetails.cardExpirationYear;
    const expMonth = ccDetails.cardExpirationMonth - 1;
    const daysInExpMonth = new Date(expYear, ccDetails.cardExpirationMonth, 0).getDate();

    const expDate = Date.parse(new Date(expYear, expMonth, daysInExpMonth));
    const today = Date.parse(
      new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    );

    return today <= expDate;
  };

  function isValidCardDetails(paymentDetails, isCreditCardValid) {
    return (
      paymentDetails.creditCardDetails &&
      paymentDetails.creditCardDetails.cardNumber !== "" &&
      isCreditCardValid(paymentDetails.creditCardDetails)
    );
  }

  useEffect(() => {
    if (payment_result_status === "success") {
      if (isEmpty(paymentResult?.errorMessage)) {
        dispatch(getPayments(accountCode));
        dispatch(getInvoices(accountCode));
        dispatch(getBillingSummary(accountCode));
        reset();
      }
      dispatch(setPaymentResultStatus(null));
      setIsCompleted(true);
    } else if (payment_result_status === "failed") {
      setIsCompleted(true);
      dispatch(setPaymentResultStatus(null));
      setIsError(true);
      setPayment({ amount: 0, card: 0 });
    }
  }, [accountCode, dispatch, paymentResult?.errorMessage, payment_result_status, reset]);

  useEffect(() => {
    if (!paymentDetails && accountCode) {
      dispatch(getPaymentDetails(accountCode));
    }

    if (!billingSummary && accountCode) {
      dispatch(getBillingSummary(accountCode));
    }

    if (!maxAmount) {
      dispatch(getMaxAllowedAmount());
    }
  }, [accountCode, dispatch, maxAmount, paymentDetails, billingSummary]);

  /**
   * After initial data was loaded
   */
  useEffect(() => {
    if (payment_details_status === "success") {
      const hasCreditCardDetailsAndIsValid = isValidCardDetails(paymentDetails, isCreditCardValid);
      setHasCreditCardDetails(hasCreditCardDetailsAndIsValid);
      setPaymentMethod(hasCreditCardDetailsAndIsValid ? PAYMENT_METHOD.SAVED : PAYMENT_METHOD.NEW);
    }
  }, [hasCreditCardDetails, paymentDetails, payment_details_status, reset]);

  const tryAgain = () => {
    setPayNowClicked(false);
    setIsError(false);
    setIsCompleted(false);
    dispatch(setPaymentError(null));
    dispatch(setPaymentResult(null));
  };

  const onClose = () => {
    setPayNowClicked(false);
    setPayment({ amount: 0, card: 0 });
  };

  const onPaymentResponseClose = () => {
    setPayNowClicked(false);
    setIsCompleted(false);
    setPayment({ amount: 0, card: 0 });
    history.push(defaultNavigationPath);
  };

  const onCancel = () => {
    setDisplayConfirmation(false);
  };

  const doAfterConfirmation = () => {
    setDisplayConfirmation(false);
    setPayNowClicked(true);
  };

  const parseNumber = useCallback(
    value =>
      value
        ? parseFloat(
            value
              .replaceAll(locale == language.LANGUAGE_ISOCODE.French ? "." : ",", "")
              .replace(",", ".")
          )
        : 0.0,
    [locale, language.LANGUAGE_ISOCODE]
  );

  const debouncedChange = useMemo(
    () =>
      debounce(value => {
        const formattedNumber = intl.formatNumber(parseNumber(value), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        const amount = formattedNumber.replaceAll(/\s/g, ".");
        setValue("amount", amount);
      }, 1000),
    [intl, parseNumber, setValue]
  );

  const handleChange = useCallback(value => debouncedChange(value), [debouncedChange]);

  return (
    <Layout>
      <Heading>
        <FormattedMessage id="lbl.make_a_payment" />
      </Heading>
      {(!payNowClicked || (payNowClicked && paymentMethod === PAYMENT_METHOD.SAVED)) && (
        <Flex
          sx={{
            flexDirection: "column",
          }}>
          <Flex
            variant="layout.serviceCard"
            my="0"
            sx={{
              flexDirection: "column",
              width: ["100%", "100%", "56.25rem"],
            }}>
            <Flex sx={{ width: "100%", flexDirection: "column" }}>
              <form onSubmit={handleSubmit(payNowClick)}>
                {!billingSummary ? (
                  <Flex sx={{ width: "13.625rem" }}>
                    <MakePaymentSkeleton />
                  </Flex>
                ) : (
                  <Flex
                    sx={{
                      width: "13.625rem",
                      ml: "-1rem",
                      fontSize: 6,
                      color: "primary",
                      fontWeight: "semiBold",
                      "::before": {
                        position: "relative",
                        height: 0,
                        top: "2rem",
                        left: locale == language.LANGUAGE_ISOCODE.French ? "12.0rem" : "1.6rem",
                        content: "'$'",
                      },
                    }}>
                    <FormInput
                      autoComplete="off"
                      sx={{
                        fontSize: 6,
                        textAlign: "center",
                        color: "primary",
                        fontWeight: "semiBold",
                      }}
                      name="amount"
                      label="lbl.amount_to_pay"
                      placeholder="0"
                      {...reactHookFormHandle}
                      onChange={event => handleChange(event.target.value)}
                      validations={{
                        required: true,
                        validate: {
                          min: value => {
                            return (
                              (parseNumber(value) < 0.01 &&
                                intl.formatMessage({ id: "err.invalid_amount" })) ||
                              true
                            );
                          },
                          max: value => {
                            return (
                              (parseNumber(value) > maxAmount &&
                                intl.formatMessage({ id: "err.invalid_amount" })) ||
                              true
                            );
                          },
                        },
                      }}
                      maxLength={10}
                    />
                  </Flex>
                )}

                <FormInput
                  type="checkbox"
                  wrapperProps={{ my: "medium" }}
                  name="tcAccepted"
                  defaultValue={false}
                  {...reactHookFormHandle}
                  control={control}
                  validations={{
                    required: {
                      value: true,
                      message: intl.formatMessage(
                        { id: "err.mandatory_field" },
                        {
                          field: intl.formatMessage({ id: "lbl.one_time_payment_consent" }),
                        }
                      ),
                    },
                  }}>
                  <Text px="small">
                    <FormattedMessage id="lbl.one_time_payment_consent" />
                  </Text>
                </FormInput>

                <Flex
                  sx={{
                    flexDirection: ["column", "column", "row"],
                    justifyContent: "flex-end",
                    alignItems: ["flex-end", "flex-end", "stretch"],
                    gap: "1rem",
                  }}>
                  {hasCreditCardDetails && (
                    <Button
                      variant="paymentAction"
                      onClick={() => !payNowClicked && setPaymentMethod(PAYMENT_METHOD.SAVED)}>
                      {payNowClicked && (
                        <Flex
                          sx={{
                            justifyContent: "center",
                            alignItems: "center",
                            height: "4.75rem",
                          }}>
                          <Spinner size={30} />
                        </Flex>
                      )}
                      {!payNowClicked && (
                        <Flex sx={{ flexDirection: "column", textAlign: "center" }}>
                          <Text variant="subheadline" sx={{ whiteSpace: "initial !important" }}>
                            <FormattedMessage id="lbl.existing_card" />
                          </Text>
                          <Flex
                            sx={{
                              justifyContent: "space-between",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            mt="default">
                            <Flex sx={{ textAlign: "left" }}>
                              <Text variant="heading3">
                                {"\u2022\u2022\u2022\u2022 \u2022\u2022\u2022\u2022 \u2022\u2022\u2022\u2022 " +
                                  paymentDetails.creditCardDetails.cardNumber.substring(
                                    paymentDetails.creditCardDetails.cardNumber.length - 4,
                                    paymentDetails.creditCardDetails.cardNumber.length
                                  )}
                              </Text>
                            </Flex>
                            <BaseIcon name="visa" />
                          </Flex>
                        </Flex>
                      )}
                    </Button>
                  )}

                  <Button
                    variant="paymentAction"
                    disabled={payNowClicked}
                    onClick={() => setPaymentMethod(PAYMENT_METHOD.NEW)}>
                    <Flex sx={{ flexDirection: "column", textAlign: "center" }}>
                      <Text variant="subheadline" sx={{ whiteSpace: "initial !important" }}>
                        <FormattedMessage id="lbl.use_new_card" />
                      </Text>
                    </Flex>
                  </Button>
                </Flex>
              </form>
            </Flex>
          </Flex>

          <GoBack onBack={() => history.goBack()} />
        </Flex>
      )}
      {payNowClicked && !isCompleted && (
        <HostedCcOperationRequest
          amount={payment.amount}
          card={payment.card}
          responseUrl={encodeURIComponent(window.location.pathname)}
          onClose={onPaymentResponseClose}
          onCancel={onClose}
          paymentMethod={paymentMethod}
        />
      )}

      <StyledModalMessage
        isOpen={displayconfirmation}
        message={intl.formatMessage(
          { id: "lbl.payment_confirmation" },
          { amount: <SelfcareAmount amount={payment.amount} /> }
        )}
        onRequestClose={onCancel}
        type="error">
        <Button variant="secondary" onClick={onCancel}>
          <FormattedMessage id="lbl.cancel" />
        </Button>

        <Button ml="default" onClick={doAfterConfirmation}>
          <FormattedMessage id="lbl.yes" />
        </Button>
      </StyledModalMessage>

      <PaymentResponseModal
        isDisplayPaymentResponse={isCompleted && payNowClicked}
        onPaymentResponseClose={onPaymentResponseClose}
        tryAgain={tryAgain}
        response={paymentResult}
        isError={isError || !isEmpty(paymentResult?.errorMessage)}
        isPayment={true}
      />
    </Layout>
  );
};

export default MakePayment;
