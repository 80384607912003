import React from "react";

const Phone = ({ fill = "#FFF", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 88 88" fill={fill} {...props}>
    <defs></defs>

    <g>
      <g id="Layer_1">
        <g id="Layer_1-2" data-name="Layer_1">
          <path d="M57.5,11.3h-26.9c-4.2,0-7.7,3.4-7.7,7.7v50c0,4.2,3.5,7.7,7.7,7.7h26.9c4.2,0,7.7-3.4,7.7-7.7V19c0-4.2-3.5-7.7-7.7-7.7ZM61.3,69c0,2.1-1.7,3.8-3.8,3.8h-26.9c-2.1,0-3.8-1.7-3.8-3.8V19c0-2.1,1.7-3.8,3.8-3.8h5l1.4,1.4c.4.4.8.6,1.4.6h11.5c.5,0,1-.2,1.4-.6l1.4-1.4h5c2.1,0,3.8,1.7,3.8,3.8v50h-.2Z" />
        </g>
      </g>
    </g>
  </svg>
);
export default Phone;
