import React from "react";

const MyInvoices = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 88 88" {...props}>
    <g>
      <g id="billing">
        <g>
          <path
            fill="#FFF"
            d="M66.3,31.5l-14.6-14.6c-.3-.3-.7-.5-1.1-.5h-22.8c-3.6,0-6.5,2.9-6.5,6.5v42.3c0,3.6,2.9,6.5,6.5,6.5h32.5c3.6,0,6.5-2.9,6.5-6.5v-32.5c0-.4-.2-.8-.5-1.1ZM52.1,21.9l9.1,9.1h-5.8c-1.8,0-3.3-1.5-3.3-3.3v-5.8ZM60.3,68.4H27.7c-1.8,0-3.3-1.5-3.3-3.3V22.9c0-1.8,1.5-3.3,3.3-3.3h21.1v8.1c0,3.6,2.9,6.5,6.5,6.5h8.1v30.9c0,1.8-1.5,3.3-3.3,3.3Z"
          />
          <g>
            <path
              fill="#FFF"
              d="M55.4,60.3h-22.8c-.9,0-1.6.7-1.6,1.6s.7,1.6,1.6,1.6h22.8c.9,0,1.6-.7,1.6-1.6s-.7-1.6-1.6-1.6Z"
            />
            <path
              fill="#FFF"
              d="M37.5,48.9c-1.8,0-3.3-1.5-3.3-3.3s-.7-1.6-1.6-1.6-1.6.7-1.6,1.6c0,3,2.1,5.5,4.9,6.3v1.9c0,.9.7,1.6,1.6,1.6s1.6-.7,1.6-1.6v-1.9c2.8-.7,4.9-3.3,4.9-6.3s-2.9-6.5-6.5-6.5-3.3-1.5-3.3-3.3,1.5-3.3,3.3-3.3,3.3,1.5,3.3,3.3.7,1.6,1.6,1.6,1.6-.7,1.6-1.6c0-3-2.1-5.5-4.9-6.3v-1.9c0-.9-.7-1.6-1.6-1.6s-1.6.7-1.6,1.6v1.9c-2.8.7-4.9,3.3-4.9,6.3s2.9,6.5,6.5,6.5,3.3,1.5,3.3,3.3-1.5,3.3-3.3,3.3Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default MyInvoices;
