import React from "react";

const LogOut = ({ stroke = "#001E62", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 88 88"
    fill="none"
    stroke={stroke}
    strokeLinecap="round"
    strokeMiterlimit={10}
    strokeWidth="0.25rem"
    {...props}>
    <g>
      <g id="Log_out">
        <g>
          <path d="M37.8,64h-7.5c-2.9,0-5.2-2.3-5.2-5.2v-29.5c0-2.9,2.3-5.2,5.2-5.2h7.5" />
          <path d="M53.9,33.7l8.2,8.2c1.2,1.2,1.2,3,0,4.2l-8.2,8.2" />
          <line x1="39" y1="44" x2="61" y2="44" />
        </g>
      </g>
    </g>
  </svg>
);
export default LogOut;
