import React from "react";

const ViewSummary = ({ stroke = "#001E62", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 88 88"
    fill="none"
    stroke={stroke}
    strokeLinecap="round"
    strokeMiterlimit={10}
    strokeWidth="0.25rem"
    {...props}>
    <g>
      <g id="View">
        <g>
          <path d="M65.5,35.3v-11.7c0-.9-.7-1.7-1.7-1.7h-11.7" />
          <path d="M39.5,30h-11.6c-3,0-5.4,2.4-5.4,5.4v25.2c0,3,2.4,5.4,5.4,5.4h25.2c3,0,5.4-2.4,5.4-5.4v-12.6" />
          <line x1="64.5" y1="23" x2="39" y2="48.5" />
        </g>
      </g>
    </g>
  </svg>
);
export default ViewSummary;
