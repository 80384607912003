import { FormattedMessage, useIntl } from "react-intl";
import React, { useState, useContext } from "react";
import _ from "lodash";

import { Flex, Text, Heading } from "@theme-ui/components";

import { PRODUCT_STATUS } from "../../pages/UpdatePackage/JSMethods";
import UpdatePackage from "../../pages/UpdatePackage/UpdatePackage";
import * as JSMethods from "../../pages/UpdatePackage/JSMethods";
import HighlightedElement from "./HighlightedElement";
import RegularElement from "./RegularElement";
import GoBack from "../GoBack";
import { MESSAGES_CODES } from "../../common/Constants";
import { useSelector } from "react-redux";
import AdditionalSrvSkeleton from "./AdditionalSrvSkeleton";

import { SelfcareIntlContext } from "../../contexts/SelfcareIntlContext";
import { BrandingContext } from "../../contexts/BrandingContext";

const ServiceAgreementPackage = ({ additionalSrv, configuration, onBack, ...props }) => {
  const intl = useIntl();
  const { locale } = useContext(SelfcareIntlContext);
  const { language } = useContext(BrandingContext);

  const [showPlan, setShowPlan] = useState(false);
  const { account, master } = useSelector(state => state.user);
  const { additional_srv_status } = useSelector(state => state.plan);

  const isFrench = locale == language.LANGUAGE_ISOCODE.French;
  let mediationFieldsServiceTypes = "";
  const isActive =
    account &&
    master &&
    [MESSAGES_CODES.ACTIVE].includes(account.accountStatus) &&
    [MESSAGES_CODES.ACTIVE].includes(master.statusMessage);

  if (configuration && configuration.data && configuration.data.options) {
    let mediationFieldsServiceTypesField = configuration.data.options.find(
      element => element.key === "MEDIATION_FIELDS_SERVICE_TYPES"
    );
    mediationFieldsServiceTypes = mediationFieldsServiceTypesField
      ? mediationFieldsServiceTypesField.value
      : "";
  }

  const getServices = additionalSrv => {
    const mapServiceToHtmlElement = (key, service, isService) => {
      // do not display services not assigned
      if (
        _.size(service.instances) === 0 ||
        !service.visible ||
        (_.size(service.instances) === 1 &&
          service.instances[0] &&
          service.instances[0].status === false)
      ) {
        return null;
      }

      let serviceInstancesHtml = [];
      _.each(service.instances, (serviceInstance, serviceId) => {
        let activationDate =
          !service.mandatory && serviceInstance.activationDate
            ? intl.formatDate(serviceInstance.activationDate)
            : "";

        let expirationDate =
          !service.mandatory && serviceInstance.expirationDate
            ? intl.formatDate(serviceInstance.expirationDate)
            : "";

        serviceInstancesHtml.push(
          isService ? (
            <HighlightedElement
              key={key + serviceId}
              activationDate={activationDate}
              expirationDate={expirationDate}
              description={service.description}
            />
          ) : (
            <RegularElement
              key={key + serviceId}
              description={service.description}
              activationDate={activationDate}
              expirationDate={expirationDate}
              serviceDetails={{
                key: serviceInstance.key,
                accessKey: serviceInstance.accessKey,
                emailUsername: serviceInstance.emailUsername,
                keyLabel: serviceInstance.keyLabel,
                accessKeyLabel: serviceInstance.accessKeyLabel,
                emailUsernameLabel: serviceInstance.emailUsernameLabel,
                type: service.type,
                basicType: service.basicType,
                udfs: serviceInstance.udfs,
                mediationFieldsServiceTypes: mediationFieldsServiceTypes,
              }}
            />
          )
        );
      });
      return serviceInstancesHtml;
    };

    const mapFeatureToHtmlElement = (key, feature) => {
      // do not display features not assigned
      if (feature.idStatusMap[0] === false || !feature.selected) {
        return null;
      }

      return <RegularElement key={key} description={feature.description} />;
    };

    //start of the list
    const mapSubProductWithNoVisibleServicesToHtml = subproduct => {
      let subproductElements = [];
      // do not display subProducts without services assigned
      let hasNoVisibleServices =
        !JSMethods.subProductHasVisibleAndSelectedServicesOrFeatures(subproduct);

      if (
        hasNoVisibleServices &&
        subproduct.status === PRODUCT_STATUS.ENABLED &&
        subproduct.visible &&
        !subproduct.exclusivityGroup
      ) {
        subproductElements.push(
          <HighlightedElement key={"key" + subproduct.code} description={subproduct.description} />
        );
      }

      return subproductElements;
    };

    const mapSubProductAndSubEntitiesToHtml = (subproduct, index, isLeaf) => {
      let subproductElements = [];

      // do not display subProducts UNNASIGNED
      if (PRODUCT_STATUS.UNASSIGNED === subproduct.status) {
        return [];
      }

      // do not display subProducts without services assigned
      if (!JSMethods.subProductHasVisibleAndSelectedServicesOrFeatures(subproduct)) {
        return [];
      }

      subproductElements.push(
        <HighlightedElement key={subproduct.code + index} description={subproduct.description} />
      );

      subproductElements.push(
        ...subproduct.subProductList
          .filter(product => product.status === PRODUCT_STATUS.ENABLED && product.exclusivityGroup)
          .map(product => {
            return <RegularElement key={"key" + product.code} description={product.description} />;
          })
      );

      subproductElements.push(
        ...subproduct.serviceList.map((service, index) => {
          return mapServiceToHtmlElement(subproduct.code + service.code + index, service, false);
        })
      );

      subproductElements.push(
        ...subproduct.subProductList.map(subSubProduct =>
          mapSubProductWithNoVisibleServicesToHtml(subSubProduct)
        )
      );

      subproductElements.push(
        ...subproduct.serviceList.map((service, index) => {
          return mapServiceWithFeatures(service, index);
        })
      );

      if (!isLeaf) {
        subproductElements.push(
          ...subproduct.subProductList.map((subSubProduct, index) => {
            return mapSubProductAndSubEntitiesToHtml(subSubProduct, index, true);
          })
        );
      }
      let filteredSubproductElements = subproductElements.filter(
        el => !!el && (!Array.isArray(el) || el.length)
      );
      if (filteredSubproductElements.length) {
        return (
          <Flex key={subproduct.code + index} my="small" sx={{ flexDirection: "column" }}>
            {subproductElements}
          </Flex>
        );
      } else {
        return [];
      }
    };

    const mapServiceWithFeatures = (service, index) => {
      if (!service.instances[_.keys(service.instances)[0]].features.length) {
        return [];
      }

      let serviceElements = [];
      _.each(service.instances, (serviceInstance, serviceId) => {
        let featuresByGroupCode = new Map();
        for (let feature of serviceInstance.features) {
          if (
            Object.keys(feature.idStatusMap).length === 0 ||
            feature.idStatusMap[0] === false ||
            !feature.selected
          ) {
            continue;
          }

          let crtValue = featuresByGroupCode.get(feature.group);
          if (crtValue) {
            crtValue.groupFeatures.push(feature);
          } else {
            featuresByGroupCode.set(feature.group, {
              groupDescription: feature.groupDescription,
              groupFeatures: [feature],
            });
          }
        }

        featuresByGroupCode.forEach((value, key) => {
          let serviceFeatureElements = [];

          serviceFeatureElements.push(
            <HighlightedElement
              key={service.code + serviceId + index}
              description={value.groupDescription}
            />
          );

          serviceFeatureElements.push(
            ...value.groupFeatures.map((feature, index) => {
              return mapFeatureToHtmlElement(
                service.code + serviceId + feature.code + index,
                feature
              );
            })
          );

          serviceElements.push(
            <Flex
              key={service.code + serviceId + key + index}
              my="small"
              sx={{ flexDirection: "column" }}>
              {serviceFeatureElements}
            </Flex>
          );
        });
      });

      return serviceElements;
    };

    if (!additionalSrv || !additionalSrv.product) {
      return <></>;
    }

    let elements = [];
    elements.push(
      ...additionalSrv.product.subProductList
        .filter(
          product =>
            product.status === PRODUCT_STATUS.ENABLED &&
            product.visibile &&
            product.exclusivityGroup &&
            !JSMethods.subProductHasVisibleAndSelectedServicesOrFeatures(product)
        )
        .map(product => {
          return (
            <HighlightedElement key={"key" + product.code} description={product.description} />
          );
        })
    );

    let serviceList = additionalSrv.product.serviceList;
    elements.push(
      ...serviceList.map((service, index) => {
        return mapServiceToHtmlElement(service.code + index, service, true);
      })
    );

    elements.push(
      ...additionalSrv.product.subProductList.map(aSubProduct =>
        mapSubProductWithNoVisibleServicesToHtml(aSubProduct)
      )
    );

    elements.push(
      ...serviceList.map((service, index) => {
        return mapServiceWithFeatures(service, index);
      })
    );

    elements.push(
      ...additionalSrv.product.subProductList
        .filter(
          subproduct =>
            subproduct.visible ||
            (!subproduct.visible &&
              JSMethods.subProductHasVisibleAndSelectedServicesOrFeatures(subproduct))
        )
        .map((subproduct, index) => {
          return mapSubProductAndSubEntitiesToHtml(subproduct, index, false);
        })
    );

    return elements;
  };

  const onBackToServices = () => {
    onBack();
    setShowPlan(false);
  };

  const onCancel = () => {
    window.scrollTo(0, 0);
    setShowPlan(false);
  };

  const ServicesAndFeatures = () => {
    let productServices = getServices(additionalSrv);

    // filter out empty elements
    let actualProductServices =
      !productServices || !(productServices instanceof Array)
        ? []
        : productServices.filter(
            e => (e instanceof Array && e.length) || (!(e instanceof Array) && e)
          );
    return (
      <Flex
        mb="default"
        sx={{ flexDirection: "column", width: ["100%", "100%", "55.75rem", "55.75rem"] }}>
        <Flex
          mt={[0, "default", "medium"]}
          sx={{
            flexDirection: isFrench ? ["column", "row"] : "row",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}>
          <Heading>
            <FormattedMessage id="lbl.services_and_features" />
          </Heading>
          {isActive && additionalSrv && (
            <Text
              variant="link"
              onClick={() => setShowPlan(true)}
              sx={{
                fontSize: [3, 3, 4, 4],
                color: "primary",
                mt: [0, "default", "medium"],
                mb: ["small", "default", "medium"],
                alignSelf: isFrench ? ["end", "center"] : "center",
                whiteSpace: "initial",
              }}>
              <FormattedMessage id="lbl.update_plan" />
            </Text>
          )}
        </Flex>
        {additional_srv_status === "loading" && <AdditionalSrvSkeleton />}
        {additionalSrv && additionalSrv.product && !actualProductServices.length && (
          <Flex
            sx={{
              textAlign: ["center", "left", "left"],
              flexDirection: "column",
            }}>
            <Text color="primary">
              <FormattedMessage id="lbl.no_visible_services" />
            </Text>
          </Flex>
        )}

        {additional_srv_status === "success" && actualProductServices.length > 0 && (
          <Flex
            bg="contentBg"
            p={["small", "default", "larger", "larger"]}
            sx={{
              border: "1px solid",
              borderColor: "border",
              flexDirection: "column",
              borderRadius: "card",
              ...props.sx,
            }}>
            {actualProductServices}
          </Flex>
        )}
        <GoBack onBack={onBack} />
      </Flex>
    );
  };

  return (
    <>
      {!showPlan ? (
        <ServicesAndFeatures />
      ) : (
        <UpdatePackage
          actualPackage={additionalSrv.product}
          onSubmit={onBackToServices}
          onCancel={onCancel}
        />
      )}
    </>
  );
};

export default ServiceAgreementPackage;
