import React from "react";

const Call = ({ ...props }) => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M19.0012 26C17.5532 25.9998 16.1408 25.5506 14.9588 24.7142C13.7767 23.8778 12.8832 22.6954 12.4012 21.33C12.3441 21.2037 12.3139 21.0668 12.3125 20.9282C12.3112 20.7896 12.3386 20.6522 12.3932 20.5248C12.4477 20.3973 12.5282 20.2826 12.6294 20.1879C12.7306 20.0933 12.8505 20.0206 12.9813 19.9747C13.1121 19.9288 13.251 19.9106 13.3892 19.9212C13.5274 19.9318 13.6619 19.9711 13.7841 20.0365C13.9064 20.1019 14.0137 20.1919 14.0992 20.301C14.1848 20.41 14.2468 20.5357 14.2812 20.67C14.6234 21.649 15.2614 22.4973 16.1071 23.0976C16.9528 23.6978 17.9641 24.0203 19.0012 24.0203C20.0382 24.0203 21.0496 23.6978 21.8953 23.0976C22.7409 22.4973 23.379 21.649 23.7212 20.67C23.7556 20.5357 23.8176 20.41 23.9031 20.301C23.9887 20.1919 24.096 20.1019 24.2182 20.0365C24.3405 19.9711 24.475 19.9318 24.6132 19.9212C24.7514 19.9106 24.8903 19.9288 25.0211 19.9747C25.1519 20.0206 25.2717 20.0933 25.373 20.1879C25.4742 20.2826 25.5547 20.3973 25.6092 20.5248C25.6637 20.6522 25.6912 20.7896 25.6898 20.9282C25.6884 21.0668 25.6583 21.2037 25.6012 21.33C25.1192 22.6954 24.2256 23.8778 23.0436 24.7142C21.8615 25.5506 20.4492 25.9998 19.0012 26Z"
      fill="#FF6D70"
    />
    <path
      d="M32 14H31.64C30.9577 11.1614 29.3398 8.63506 27.0469 6.82795C24.7539 5.02084 21.9195 4.03815 19 4.03815C16.0805 4.03815 13.2461 5.02084 10.9531 6.82795C8.6602 8.63506 7.04229 11.1614 6.36 14H6C4.93913 14 3.92172 14.4214 3.17157 15.1716C2.42143 15.9217 2 16.9391 2 18V19C2 20.0609 2.42143 21.0783 3.17157 21.8284C3.92172 22.5786 4.93913 23 6 23H6.17C6.6467 26.0617 8.20132 28.8524 10.5537 30.8692C12.906 32.8861 15.9014 33.9964 19 34C19.4945 34 19.9778 33.8534 20.3889 33.5787C20.8 33.304 21.1205 32.9135 21.3097 32.4567C21.4989 31.9999 21.5484 31.4972 21.452 31.0123C21.3555 30.5273 21.1174 30.0819 20.7678 29.7322C20.4181 29.3826 19.9727 29.1445 19.4877 29.048C19.0028 28.9516 18.5001 29.0011 18.0433 29.1903C17.5865 29.3795 17.196 29.6999 16.9213 30.1111C16.6466 30.5222 16.5 31.0055 16.5 31.5C16.5 31.57 16.5 31.64 16.5 31.71C14.0826 31.1458 11.927 29.7805 10.3838 27.8361C8.84062 25.8916 8.00048 23.4824 8 21V17C8 14.0826 9.15893 11.2847 11.2218 9.22182C13.2847 7.15892 16.0826 5.99999 19 5.99999C21.9174 5.99999 24.7153 7.15892 26.7782 9.22182C28.8411 11.2847 30 14.0826 30 17V22C30 22.2652 30.1054 22.5196 30.2929 22.7071C30.4804 22.8946 30.7348 23 31 23H32C33.0609 23 34.0783 22.5786 34.8284 21.8284C35.5786 21.0783 36 20.0609 36 19V18C36 16.9391 35.5786 15.9217 34.8284 15.1716C34.0783 14.4214 33.0609 14 32 14ZM4 19V18C4 17.4696 4.21071 16.9608 4.58579 16.5858C4.96086 16.2107 5.46957 16 6 16V21C5.46957 21 4.96086 20.7893 4.58579 20.4142C4.21071 20.0391 4 19.5304 4 19ZM34 19C34 19.5304 33.7893 20.0391 33.4142 20.4142C33.0391 20.7893 32.5304 21 32 21V16C32.5304 16 33.0391 16.2107 33.4142 16.5858C33.7893 16.9608 34 17.4696 34 18V19Z"
      fill="#001E62"
    />
    <path
      d="M15.5 18C16.3284 18 17 17.3284 17 16.5C17 15.6716 16.3284 15 15.5 15C14.6716 15 14 15.6716 14 16.5C14 17.3284 14.6716 18 15.5 18Z"
      fill="#001E62"
    />
    <path
      d="M22.5 18C23.3284 18 24 17.3284 24 16.5C24 15.6716 23.3284 15 22.5 15C21.6716 15 21 15.6716 21 16.5C21 17.3284 21.6716 18 22.5 18Z"
      fill="#001E62"
    />
  </svg>
);
export default Call;
