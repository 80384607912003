import React from "react";

const CogecoRemove = ({ fill = "#001E62", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 88 88" fill={fill} {...props}>
    <g>
      <g id="Remove">
        <g>
          <path d="M65,27.5h-10v-4.8c0-2.3-1.9-4.2-4.2-4.2h-14.5c-2.3,0-4.2,1.9-4.2,4.2v4.8h-10c-1.1,0-2,.9-2,2s.9,2,2,2h3.5v31.4c0,3.1,2.5,5.6,5.6,5.6h24.9c3.1,0,5.6-2.5,5.6-5.6v-31.4h3.5c1.1,0,2-.9,2-2s-.9-2-2-2ZM36,22.8c0-.1.1-.2.2-.2h14.5c.1,0,.2.1.2.2v4.8h-15v-4.8ZM57.5,62.9c0,.9-.7,1.6-1.6,1.6h-24.9c-.9,0-1.6-.7-1.6-1.6v-31.4h28v31.4Z" />
          <path d="M39,38.5c-1.1,0-2,.9-2,2v14.5c0,1.1.9,2,2,2s2-.9,2-2v-14.5c0-1.1-.9-2-2-2Z" />
          <path d="M48,38.5c-1.1,0-2,.9-2,2v14.5c0,1.1.9,2,2,2s2-.9,2-2v-14.5c0-1.1-.9-2-2-2Z" />
        </g>
      </g>
    </g>
  </svg>
);
export default CogecoRemove;
