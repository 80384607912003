import { Box } from "@theme-ui/components";
import React from "react";

const ViewUsageDetails = ({ ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 88 88"
    width="32"
    height="32"
    {...props}>
    <g>
      <path
        fill="#001E62"
        d="M72.163,50.53c0-0.004,0-0.008,0-0.011c0-0.01-0.001-0.019-0.001-0.029c0-0.027,0.001-0.054,0.001-0.081
		c-0.001-0.156-0.003-0.312-0.006-0.468c-0.001-0.047-0.002-0.093-0.003-0.14C72.151,49.7,72.148,49.6,72.145,49.5
		c-0.21-6.565-2.372-12.281-6.375-16.941c-0.236-0.274-0.483-0.538-0.739-0.794c-5.155-5.779-12.662-9.41-21.031-9.41c0,0,0,0,0,0
		c0,0,0,0,0,0h0c-8.259,0-15.687,3.555-20.838,9.218l-0.184,0.184c-0.26,0.26-0.512,0.529-0.752,0.809
		c-3.954,4.62-6.127,10.298-6.365,16.782c-0.016,0.388-0.024,0.779-0.024,1.171v0v0c0,0,0,0,0,0c0,0.005,0,0.011,0,0.016
		c0,0.002,0,0.004,0,0.006c0,0.004,0,0.007,0,0.011c0,0.004,0,0.007,0,0.011v0c0,0.01,0.001,0.019,0.001,0.029
		c0,0.027-0.001,0.054-0.001,0.081c0.001,0.156,0.003,0.312,0.006,0.468c0.001,0.047,0.002,0.093,0.003,0.14
		c0.003,0.101,0.006,0.201,0.009,0.301c0.004,0.115,0.008,0.229,0.013,0.343c0.192,4.463,1.496,8.807,3.729,12.676
		c0.577,0.999,1.854,1.341,2.852,0.764l4.726-2.729c0.987-0.57,1.325-1.831,0.755-2.818c-0.57-0.987-1.831-1.325-2.818-0.755
		l-2.879,1.662c-0.186-0.394-0.361-0.794-0.526-1.2c-0.894-2.211-1.421-4.549-1.593-6.922h3.267c1.139,0,2.063-0.924,2.063-2.063
		c0-1.139-0.924-2.063-2.063-2.063h-3.267c0.171-2.353,0.69-4.671,1.571-6.865c0.171-0.425,0.354-0.844,0.548-1.256l2.879,1.662
		c0.987,0.57,2.248,0.232,2.818-0.755l0,0c0.57-0.987,0.232-2.248-0.755-2.818l-2.861-1.652c0.748-1.044,1.582-2.026,2.493-2.937
		l0.513-0.514c0.909-0.91,1.888-1.745,2.928-2.493l1.656,2.868c0.57,0.987,1.831,1.325,2.818,0.755l0,0
		c0.987-0.57,1.325-1.831,0.755-2.818l-1.676-2.903c0.195-0.092,0.391-0.182,0.589-0.269c0.105-0.046,0.21-0.091,0.315-0.135
		c2.302-0.968,4.747-1.536,7.232-1.716v3.292c0,1.139,0.924,2.063,2.063,2.063s2.063-0.924,2.063-2.063V26.63
		c2.407,0.173,4.778,0.711,7.018,1.625c0.289,0.118,0.575,0.241,0.858,0.37c0.088,0.04,0.174,0.082,0.261,0.123l-1.677,2.905
		c-0.57,0.987-0.232,2.248,0.755,2.818l0,0c0.987,0.57,2.248,0.232,2.818-0.755l1.659-2.874c1.04,0.748,2.02,1.581,2.929,2.49
		l0.501,0.501c0.917,0.917,1.756,1.905,2.509,2.955l-2.869,1.656c-0.987,0.57-1.325,1.831-0.755,2.818l0,0
		c0.57,0.987,1.831,1.325,2.818,0.755l2.88-1.663c0.203,0.432,0.394,0.872,0.572,1.318c0.866,2.176,1.377,4.474,1.546,6.805h-3.267
		c-1.139,0-2.063,0.924-2.063,2.063c0,1.139,0.924,2.063,2.063,2.063h3.267c-0.173,2.373-0.7,4.711-1.593,6.922
		c-0.164,0.406-0.34,0.806-0.526,1.2l-2.879-1.662c-0.987-0.57-2.248-0.232-2.818,0.755c-0.57,0.987-0.232,2.248,0.755,2.818
		l4.713,2.721c1.006,0.581,2.292,0.236,2.873-0.77l0,0c1.765-3.058,2.962-6.416,3.464-9.911c0.139-0.967,0.229-1.949,0.265-2.942
		c0.016-0.388,0.024-0.779,0.024-1.171v0v0l0,0c0-0.005,0-0.011,0-0.016c0-0.002,0-0.004,0-0.006
		C72.163,50.537,72.163,50.533,72.163,50.53z"
      />
      <path
        fill="#FF6D72"
        d="M44.058,53.971c0.028,0,0.055-0.001,0.083-0.002c0.036-0.001,0.071-0.004,0.107-0.006
		c0.023-0.002,0.046-0.003,0.069-0.005c0.048-0.004,0.095-0.01,0.142-0.016c0.01-0.001,0.02-0.002,0.03-0.004
		c0.362-0.051,0.705-0.159,1.021-0.314l16.766-7.538c0.211-0.095,0.13-0.411-0.1-0.394l-18.438,1.416
		c-0.009,0.001-0.018,0.002-0.027,0.003c-0.049,0.004-0.097,0.009-0.145,0.015c-0.014,0.002-0.028,0.003-0.042,0.005
		c-0.051,0.007-0.102,0.015-0.152,0.025c-0.012,0.002-0.023,0.004-0.035,0.006c-0.053,0.01-0.106,0.022-0.159,0.035
		c-0.008,0.002-0.017,0.004-0.025,0.006c-0.06,0.015-0.119,0.032-0.177,0.05c-0.017,0.005-0.034,0.012-0.051,0.017
		c-0.038,0.013-0.077,0.025-0.115,0.039c-0.032,0.012-0.064,0.025-0.096,0.038c-0.022,0.009-0.044,0.017-0.065,0.026
		c-0.035,0.015-0.069,0.031-0.104,0.047c-0.018,0.008-0.036,0.016-0.053,0.025c-0.035,0.017-0.07,0.035-0.105,0.054
		c-0.016,0.008-0.032,0.017-0.048,0.026c-0.035,0.019-0.069,0.039-0.103,0.059c-0.015,0.009-0.03,0.019-0.046,0.028
		c-0.033,0.021-0.066,0.042-0.099,0.063c-0.016,0.011-0.031,0.022-0.047,0.033c-0.031,0.021-0.062,0.043-0.092,0.065
		c-0.019,0.014-0.036,0.028-0.055,0.042c-0.026,0.021-0.053,0.041-0.079,0.062c-0.034,0.028-0.068,0.058-0.101,0.087
		c-0.009,0.008-0.018,0.015-0.027,0.023c-0.535,0.485-0.916,1.136-1.062,1.871c-0.003,0.014-0.005,0.029-0.008,0.043
		c-0.008,0.042-0.016,0.085-0.022,0.128c-0.004,0.027-0.007,0.054-0.01,0.081c-0.004,0.031-0.008,0.062-0.011,0.094
		c-0.003,0.031-0.005,0.062-0.007,0.093c-0.002,0.029-0.004,0.059-0.005,0.088c-0.001,0.03-0.002,0.059-0.002,0.089
		c0,0.02-0.001,0.039-0.001,0.059c0,0.015,0.001,0.03,0.001,0.045c0,0.022,0.001,0.044,0.002,0.067
		c0.002,0.047,0.004,0.095,0.007,0.141c0.001,0.009,0.001,0.018,0.002,0.027c0.043,0.529,0.205,1.025,0.46,1.459
		c0.001,0.002,0.002,0.004,0.003,0.005c0.031,0.052,0.062,0.103,0.096,0.153c0.001,0.002,0.003,0.004,0.004,0.006
		c0.068,0.102,0.141,0.2,0.22,0.294c0.005,0.006,0.01,0.012,0.016,0.018c0.034,0.04,0.068,0.079,0.103,0.117
		c0.007,0.008,0.015,0.016,0.023,0.024c0.035,0.037,0.07,0.073,0.106,0.108c0.006,0.005,0.011,0.011,0.017,0.016
		c0.041,0.039,0.082,0.077,0.124,0.114c0.001,0.001,0.001,0.001,0.002,0.002c0.496,0.431,1.117,0.722,1.802,0.812
		c0.004,0.001,0.008,0.001,0.012,0.002c0.051,0.006,0.101,0.012,0.153,0.016c0.017,0.001,0.033,0.003,0.05,0.004
		c0.039,0.003,0.078,0.005,0.118,0.006c0.025,0.001,0.05,0.002,0.076,0.002c0.014,0,0.028,0.001,0.043,0.001
		C44.019,53.973,44.038,53.972,44.058,53.971z"
      />
    </g>
  </Box>
);
export default ViewUsageDetails;
