import React, { useContext } from "react";
import SelfcareTable from "../base/SelfcareTable";
import SARow from "../SARow/SARow";
import { Flex, Box } from "@theme-ui/components";
import GoBack from "../GoBack";

import { SelfcareIntlContext } from "../../contexts/SelfcareIntlContext";
import { BrandingContext } from "../../contexts/BrandingContext";

const HorizontalRule = () => {
  return <Box sx={{ width: "100%", height: "1px" }} bg="inactive"></Box>;
};

const ServicesTable = ({ services, goBack, currentPage, setCurrentPage }) => {
  const { locale } = useContext(SelfcareIntlContext);
  const { language } = useContext(BrandingContext);
  const isFrench = locale === language.LANGUAGE_ISOCODE.French;

  const itemsPerPage = 10;
  const gridColumns = isFrench
    ? [
        "6rem 8rem 12rem 9rem 5rem",
        "6rem 8rem 12rem 9rem 5rem",
        "0.2fr 0.4fr 0.4fr 0.3fr 0.3fr",
        "0.25fr 0.4fr 0.5fr 0.3fr 0.3fr",
      ]
    : [
        "6rem 8.5rem 18rem 6rem 7rem",
        "6rem 8.5rem 18rem 6rem 7rem",
        "0.25fr 0.4fr 0.4fr 0.3fr 0.3fr",
        "0.25fr 0.4fr 0.5fr 0.3fr 0.3fr",
      ];

  return (
    <Flex sx={{ flexDirection: "column" }}>
      <SelfcareTable
        px="large"
        sx={{ width: ["51rem", "50rem", "70rem", "70rem"] }}
        numRows={services.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        gridColumns={gridColumns}
        itemsPerPage={itemsPerPage}
        headers={["tooltip.status", "lbl.service_number", "lbl.package", "lbl.device", "lbl.line"]}>
        {services
          .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
          .map((item, index) => {
            return (
              <Box key={item.accountCode}>
                <SARow key={item.accountCode + index} account={item} gridColumns={gridColumns} />
                {index !== itemsPerPage - 1 && index !== services.length - 1 && <HorizontalRule />}
              </Box>
            );
          })}
      </SelfcareTable>

      <GoBack onBack={goBack} />
    </Flex>
  );
};
export default ServicesTable;
