import React from "react";

const User = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 88 88" {...props}>
    <g>
      <g id="Layer_1">
        <g id="Settings">
          <g>
            <path
              fill="#FFF"
              d="M72.2,35.7h-5.7c-.2-.5-.4-1.1-.7-1.7l4-4c.8-.8,1.2-1.8,1.2-2.9s-.4-2.1-1.2-2.9l-5.9-5.9c-.8-.8-1.8-1.2-2.9-1.2s-2.1.4-2.9,1.2l-4,4c-.6-.3-1.1-.5-1.7-.7v-5.7c0-2.3-1.9-4.1-4.1-4.1h-8.4c-2.3,0-4.1,1.9-4.1,4.1v5.7c-.6.2-1.2.5-1.7.7l-4-4c-.8-.8-1.8-1.2-2.9-1.2s-2.1.4-2.9,1.2l-5.9,5.9c-.8.8-1.2,1.8-1.2,2.9s.4,2.1,1.2,2.9l4,4c-.3.6-.5,1.1-.7,1.7h-5.7c-2.3,0-4.1,1.9-4.1,4.1v8.4c0,2.3,1.9,4.1,4.1,4.1h5.7c.2.6.4,1.1.7,1.7l-4,4c-.8.8-1.2,1.8-1.2,2.9s.4,2.1,1.2,2.9l5.9,5.9c.8.8,1.8,1.2,2.9,1.2s2.1-.4,2.9-1.2l4-4c.6.3,1.1.5,1.7.7v5.7c0,2.3,1.9,4.1,4.1,4.1h8.4c2.3,0,4.1-1.9,4.1-4.1v-5.7c.6-.2,1.1-.4,1.7-.7l4,4c.8.8,1.8,1.2,2.9,1.2s2.1-.4,2.9-1.2l5.9-5.9c.8-.8,1.2-1.8,1.2-2.9s-.4-2.1-1.2-2.9l-4-4c.3-.6.5-1.1.7-1.7h5.7c2.3,0,4.1-1.9,4.1-4.1v-8.4c0-2.3-1.9-4.1-4.1-4.1ZM62.7,50.9c-.2.5-.4.9-.6,1.4-.7,1.6-.4,3.4.8,4.6l4,4.1-6,5.9-4-4c-1.2-1.2-3.1-1.5-4.6-.8-.5.2-.9.4-1.4.6-1.6.6-2.7,2.1-2.7,3.9v5.7h-8.4v-5.7c0-1.7-1.1-3.3-2.7-3.9-.5-.2-.9-.4-1.4-.6-.5-.2-1.1-.4-1.7-.4-1.1,0-2.2.4-2.9,1.2l-4.1,4-5.9-6,4-4c1.2-1.2,1.5-3.1.8-4.6-.2-.5-.4-.9-.6-1.4-.6-1.6-2.1-2.7-3.9-2.7h-5.7v-8.4h5.7c1.7,0,3.3-1.1,3.9-2.7.2-.5.4-.9.6-1.4.7-1.5.4-3.4-.8-4.6l-4-4.1,6-5.9,4,4c1.2,1.2,3.1,1.5,4.6.8.5-.2.9-.4,1.4-.6,1.6-.6,2.7-2.1,2.7-3.8v-5.7h8.4v5.7c0,1.7,1.1,3.3,2.7,3.9.5.2.9.4,1.4.6,1.5.7,3.4.4,4.6-.8l4.1-4,5.9,6-4,4c-1.2,1.2-1.5,3.1-.8,4.6.2.5.4.9.6,1.4.6,1.6,2.1,2.7,3.8,2.7h5.7v8.4h-5.7c-1.7,0-3.3,1.1-3.9,2.7h.1Z"
            />
            <path
              fill="#FFF"
              d="M44,28.4c-8.6,0-15.6,7-15.6,15.6s7,15.6,15.6,15.6,15.6-7,15.6-15.6-7-15.6-15.6-15.6ZM44,55.5c-6.4,0-11.5-5.2-11.5-11.5s5.2-11.5,11.5-11.5,11.5,5.2,11.5,11.5-5.2,11.5-11.5,11.5Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default User;
