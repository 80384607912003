import React from "react";
import { FormattedMessage } from "react-intl";
import { Flex, Text, Progress } from "@theme-ui/components";

import { SelfcareAmount } from "../base";
import { SelfcareIcon } from "../icons";
import { isData, isMoney } from "../../common/Utilities";

const UsageRow = ({ ani, usage, unit, total, formatPercentage }) => {
  return (
    <Flex
      mb="smallish"
      sx={{
        height: "1.375rem",
        width: "100%",
        alignItems: "center",
      }}>
      <Text
        ml="-0.25rem"
        sx={{
          textAlign: "right",
          width: "9.5rem",
          fontFeatureSettings: "'tnum'",
        }}>
        {ani}
      </Text>
      {total > 0 ? (
        <Flex
          p="tiny"
          ml="smallish"
          sx={{
            backgroundColor: "progressBg",
            width: "5rem",
            borderRadius: 10,
            height: "1.174rem",
          }}>
          <Progress max={1} value={usage === 0 ? 0 : usage / total} />
        </Flex>
      ) : (
        <Flex mb="tiny">
          <Flex
            p="tiny"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "progressBg",
              width: total > 0 ? "5rem" : "7rem",
              ml: "1rem",
              borderRadius: 10,
              height: "1.375rem",
            }}>
            <SelfcareIcon name="infinity" />
          </Flex>
        </Flex>
      )}

      {total > 0 && (
        <Text
          ml="tiny"
          sx={{
            width: "3.5rem",
            textAlign: "right",
          }}>
          {usage === 0 ? 0 : formatPercentage((usage / total) * 100)}%
        </Text>
      )}

      <Text
        sx={{
          ml: "1rem",
          fontWeight: "semiBold",
          textAlign: "right",
          width: total > 0 ? "7rem" : "8rem",
        }}>
        {!isMoney(unit) ? (
          <FormattedMessage
            id={isData(unit) ? "lbl.consumedDecimal" : "lbl.consumed"}
            values={{ amount: usage, unit: <FormattedMessage id={`lbl.${unit}`} /> }}
          />
        ) : (
          <SelfcareAmount amount={usage} />
        )}
      </Text>
    </Flex>
  );
};

export default UsageRow;
