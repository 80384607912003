import React from "react";

const ChangePassword = ({ stroke = "#001E62", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 88 88"
    stroke={stroke}
    strokeMiterlimit={10}
    strokeWidth="0.25rem"
    fill="none"
    {...props}>
    <g>
      <g id="Change_password">
        <g>
          <rect x="21" y="34.5" width="46" height="40" rx="5.6" ry="5.6" />
          <path d="M58,35.1v-8.3c0-7.4-6-13.4-13.4-13.4h-.3c-7.4,0-13.4,6-13.4,13.4v8.3" />
          <circle c cx="44" cy="50.5" r="6" />
          <line x1="44" y1="56.5" x2="44" y2="64.5" />
        </g>
      </g>
    </g>
  </svg>
);
export default ChangePassword;
