import React from "react";
import ChangeUsername from "./ChangeUsername";
import ChangePassword from "./ChangePassword";
import LogOut from "./LogOut";
import Phone from "./Phone";
import Edit from "./Edit";
import NotificationBell from "./NotificationBell";
import ViewUsageDetails from "./ViewUsageDetails";
import NeedHelp from "./NeedHelp";
import Call from "./Call";
import Chat from "./Chat";
import Search from "./Search";
import Back from "./Back";
import Remove from "./CogecoRemove";
import ViewDeviceDetails from "./ViewDeviceDetails";

import Close from "../base/Close";
import AccountDetails from "./AccountDetails";
import PaymentInformation from "./PaymentInformation";
import ViewSummary from "./ViewSummary";
import MyAccount from "./MyAccount";
import Home from "./Home";
import MyServices from "./MyServices";
import MyInvoices from "./MyInvoices";
import MyPayments from "./MyPayments";
import Language from "./Language";
import User from "./User";
import RequestAssist from "./RequestAssist";
import ServiceFeatures from "./ServiceFeatures";

const CogecoIcon = ({ name, ...props }) => {
  switch (name) {
    case "home":
      return <Home />;
    case "myServices":
      return <MyServices />;
    case "myAccount":
      return <MyAccount />;
    case "accountDetails":
      return <AccountDetails />;
    case "myInvoices":
      return <MyInvoices />;
    case "myPayments":
      return <MyPayments />;
    case "language":
      return <Language {...props} />;
    case "paymentInformation":
      return <PaymentInformation />;
    case "user":
      return <User />;
    case "requestAssist":
      return <RequestAssist />;
    case "changeUsername":
      return <ChangeUsername {...props} />;
    case "change-password":
      return <ChangePassword {...props} />;
    case "logout":
      return <LogOut {...props} />;
    case "phone":
      return <Phone />;
    case "viewDeviceDetails":
      return <ViewDeviceDetails />;
    case "edit":
      return <Edit />;
    case "serviceFeatures":
      return <ServiceFeatures />;
    case "notificationBell":
      return <NotificationBell />;
    case "viewUsageDetails":
      return <ViewUsageDetails {...props} />;
    case "chat":
      return <Chat {...props} />;
    case "close":
      return <Close />;
    case "question-mark":
      return <NeedHelp />;
    case "chatPhone":
      return <Call />;
    case "search":
      return <Search />;
    case "back":
      return <Back />;
    case "remove":
      return <Remove />;
    case "viewEye":
      return <ViewSummary />;

    default:
      return null;
  }
};

export default CogecoIcon;
