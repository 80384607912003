import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { Flex, Text } from "@theme-ui/components";

import { SelfcareIntlContext } from "../../contexts/SelfcareIntlContext";
import SummaryRow from "../../components/SummaryRow/SummaryRow";
import SummaryLayout from "../../containers/SummaryLayout";
import { maskString } from "../../common/Utilities";
import { BrandingContext } from "../../contexts/BrandingContext";

const BankAccountDetails = ({
  details,
  isLoading,
  onUpdateBankAccountDetails,
  onRemoveBankAccountDetails,
  fieldWidth = "12.5rem",
}) => {
  const intl = useIntl();
  const { language } = useContext(BrandingContext);
  const { locale } = useContext(SelfcareIntlContext);
  const { master } = useSelector(state => state.user);
  const displayUpdate = ["ACTIVE", "SUSPENDED"].includes(master?.accountStatus);

  if (isLoading || !details) {
    return (
      <SummaryLayout
        title="lbl.bank_account_information"
        isLoading
        sx={{ width: "100%", maxWidth: "100%" }}
      />
    );
  }

  const bankAccountDetails = details.bankAccountDetails;
  const width = locale == language.LANGUAGE_ISOCODE.French ? "15.75rem" : fieldWidth;
  var institutionId, transitNumber;
  if (bankAccountDetails?.bankAccountNumber) {
    institutionId = bankAccountDetails.bankRoutingNumber.toString().substring(1, 4);
    transitNumber = bankAccountDetails.bankRoutingNumber.toString().substring(4);
  }
  const invalidBankAccountDetails =
    bankAccountDetails.bankAccountNumber === null ||
    bankAccountDetails.bankAccountNumber === undefined ||
    bankAccountDetails.bankAccountNumber === "";

  if (invalidBankAccountDetails) {
    return (
      <SummaryLayout
        title="lbl.bank_account_information"
        isAdd
        showUpdate={displayUpdate}
        onUpdate={onUpdateBankAccountDetails}
        sx={{ width: "100%" }}>
        <Flex mb="medium">
          <Text color="note" sx={{ fontSize: 3, textAlign: "left" }}>
            {intl.formatMessage({ id: "lbl.no_bank_account" })}
          </Text>
        </Flex>
      </SummaryLayout>
    );
  }

  return (
    <SummaryLayout
      title="lbl.bank_account_information"
      showUpdate={details.allowedToEditBankDetails && displayUpdate}
      onUpdate={onUpdateBankAccountDetails}
      showRemove
      onRemove={onRemoveBankAccountDetails}
      sx={{ width: "100%" }}>
      <SummaryRow
        tooltip={intl.formatMessage({ id: "tooltip.account_holder_name" })}
        value={bankAccountDetails.bankHolderName}
        fieldWidth={width}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "tooltip.bank_account_number" })}
        value={maskString(bankAccountDetails.bankAccountNumber)}
        fieldWidth={width}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "tooltip.bank_institution_id" })}
        value={institutionId}
        fieldWidth={width}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "tooltip.bank_transit_number" })}
        value={transitNumber}
        fieldWidth={width}
      />
    </SummaryLayout>
  );
};

export default BankAccountDetails;
